import React from "react";
import { URL_DB } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Alert,
    Modal,
    FormGroup
} from "react-bootstrap";
// import exportToCSV from "../../utils/functions/export-excel";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import swal from "sweetalert";
import "../../pages/stylePages.css";

class DefPerfiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sedes: [],
            login_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            perf_id: 0,
            perf_deno: "",
            perf_color: "",
            perf_sede_id: 0,
            filterGrilla: "",
            showModalEdit: false,
            fetchRegistros: false,
            expSql: "",
            expTitulo: "Reporte de Perfils",
            expSubtit: "",
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }

    async getPerfiles() {
        const sql = `${URL_DB}SEL_USUA_PERFIL(null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getPerfiles()]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.perf_deno) || regex.test(filtro.sede_deno)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChange = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleModalEdit = regis => {
        this.setState({
            showModalEdit: true,
            perf_id: regis.perf_id,
            perf_deno: regis.perf_deno,

            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    };

    handleModalAlta = () => {
        this.setState({
            showModalEdit: true,
            perf_id: 0,
            perf_deno: "",
            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    };

    handleModalExit = () => {
        this.setState({ showModalEdit: false });
        this.setState({
            mensajeAlerta: "No se aplicaron cambios",
            mensajeColor: "red"
        });
    };

    /*==========================================================================
        Actualizacion : Modificacion a base de datos
    *==========================================================================*/

    handleGrabar = e => {
        const sql = `${URL_DB}AM_USUA_PERFIL(${this.state.login_id},${
            this.state.perf_id
        },'${encodeURIComponent(this.state.perf_deno)}')`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalEdit: false
                    });
                    this.getPerfiles();
                }
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error + sql);
            });
    };

    handleEliminar = a_reg => {
        swal({
            title: `Baja de ${a_reg.perf_deno} `,
            text: `Para confirmar escriba la palabra:"BAJA"`,
            content: "input",
            button: {
                text: "Graba",
                closeModal: true
            }
        }).then(result => {
            if (result === "BAJA") {
                const sql = `${URL_DB}B_USUA_PERFIL(${this.state.login_id},'${a_reg.perf_id}')`;
                axios
                    .get(sql)
                    .then(response => {
                        this.setState(
                            {
                                respuestaSp: response.data[0][0].respuesta
                            },
                            () => {
                                if (this.state.respuestaSp === "OK") {
                                    this.setState({
                                        mensajeAlerta:
                                            "Registrado correctamente",
                                        mensajeColor: "green",
                                        respError: ""
                                    });
                                    this.getPerfiles(this.state.acti);
                                } else {
                                    this.setState({
                                        respError: this.state.respuestaSp
                                    });
                                    swal({
                                        title: `Intento de Baja de ${a_reg.perf_deno} `,
                                        text: `Mensaje:${this.state.respuestaSp}`
                                    });
                                }
                            }
                        );
                    })
                    .catch(error => {
                        alert("ERROR interno API al actualizar BD:" + error);
                    });
            } else {
                this.setState({
                    mensajeAlerta: "No se aplico baja",
                    mensajeColor: "red"
                });
            }
        });
    };
    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        const registrosFiltrados = this.filtrarDatos();
        const respError = this.state.respError;
        //const mensajeAlerta = this.state.mensajeAlerta;
        //const mensajeColor = this.state.mensajeColor;

        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ fontSize: "22px" }}>
                            <b>Definir Perfiles</b>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            {this.state.login_usua_nivel === "A" ? (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={this.handleModalAlta}
                                >
                                    + Perfil
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled
                                >
                                    Solo Lectura
                                </Button>
                            )}
                        </Col>
                        <Col xs={1}>
                            {/*
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                    this.setState({ showhelp: true });
                                }}
                            >
                                Ayuda
                            </Button>
                            */}
                        </Col>
                        <Col xs={1}>
                            {/*
                            {this.state.registros.length > 0 && (
                                <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={e =>
                                        exportToCSV(
                                            this.state.expTitulo,
                                            this.state.expSubtit,
                                            this.state.expSql
                                        )
                                    }
                                >
                                    <FaDownload /> Excel
                                </Button>
                            )}
                                */}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Denominación
                                            </Button>
                                        </th>

                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("canti")
                                                }
                                            >
                                                #Usuarios
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_alta_f"
                                                    )
                                                }
                                            >
                                                Alta
                                            </Button>
                                        </th>

                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{regis.perf_id}</td>
                                                <td>{regis.perf_deno}</td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.canti}
                                                </td>
                                                <td>{regis.perf_alta_f}</td>

                                                <td>
                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" && (
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={() =>
                                                                    this.handleModalEdit(
                                                                        regis
                                                                    )
                                                                }
                                                            >
                                                                <FaRegEdit />
                                                            </Button>
                                                        </>
                                                    )}

                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" &&
                                                        regis.canti === 0 && (
                                                            <>
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.handleEliminar(
                                                                            regis
                                                                        );
                                                                    }}
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                            </>
                                                        )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModalEdit}
                    onHide={this.handleModalExit}
                    dialogClassName="modal-40"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.perf_id === 0
                                ? "Alta de Perfil"
                                : `Modifica Perfil #${this.state.perf_id}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={2}>
                                    <FormGroup>
                                        #Id
                                        <FormControl
                                            type="text"
                                            name={"perf_id"}
                                            value={this.state.perf_id}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={7}>
                                    <FormGroup>
                                        Denominación
                                        <FormControl
                                            type="text"
                                            name={"perf_deno"}
                                            value={this.state.perf_deno}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs={8} style={{ textAlign: "right" }}>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={this.handleGrabar}
                                    >
                                        Grabar
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={this.handleModalExit}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Alert
                                        key="1"
                                        variant="danger"
                                        show={
                                            respError.length > 3 ? true : false
                                        }
                                    >
                                        {respError}
                                    </Alert>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default DefPerfiles;
