import React from "react";
import { NOMBRE_SIST } from "./../../constants";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";

const nivel_acceso = sessionStorage.getItem("USUARIO_NIVEL");

const Header = () => (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Navbar.Brand href="#home">{NOMBRE_SIST}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link href="cursos">Cursos</Nav.Link>
                <Nav.Link href="#">Manuales</Nav.Link>
                <Nav.Link href="videos_tutorial">Tutoriales</Nav.Link>
                <Nav.Link href="img_tutorial">Infografías</Nav.Link>
                {nivel_acceso === "A" && (
                    <>
                        <Nav.Link href="asigna_cursos">Asigna cursos</Nav.Link>
                        <Nav.Link href="asigna_masivo">Asigna masivo</Nav.Link>
                    </>
                )}
                <Nav.Link href="cuadro_videos">Cuadros</Nav.Link>
                {nivel_acceso === "A" && (
                    <>
                        <Nav.Link href="usua_tablas">Usuarios</Nav.Link>
                        <Nav.Link href="tablas_cursos">Tablas</Nav.Link>
                    </>
                )}
                <Nav.Link href="#"></Nav.Link>
            </Nav>

            <div className="collapse navbar-collapse justify-content-end">
                <Dropdown
                /*className="btn-group dropleft"*/
                >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <i className="fas fa-user"></i>{" "}
                        {sessionStorage.getItem("NOMBRE_USUARIO")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/UsuarioLogueado">
                            Datos Usuario
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => {
                                window.location = "/login";
                                sessionStorage.setItem("USUARIO", 0);
                                sessionStorage.setItem("NOMBRE_USUARIO", "");
                            }}
                        >
                            <FaUserAlt /> Cerrar Sesión
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Navbar.Collapse>
    </Navbar>
);

export default Header;
