import React from "react";
import { NOMBRE_SIST } from "./../../constants";
// import axios from "axios";
import { Container, Tab, Tabs } from "react-bootstrap";
import DefModulos from "../../components/DefModulos";
import DefCursos from "../../components/DefCursos";
import TablasLog from "../../components/TablasLog";
import ModuloSector from "../../components/ModuloSector";
import ConsCursos from "../../components/ConsCursos";
//import ActiResum from '../../components/ActiResum';
//import CobranzaLiqui from '../../components/CobranzaLiqui';

class TablasCursosPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login_usua_id: sessionStorage.getItem("USUARIO"),
            usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            toggleState: 1,
            accesos: [],
            usma_item_def: "",
            showhelp: false,
            filterGrilla: "",
            fetchingregistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }
    /*
  cargaAccesos = (a_usua_id) => {

    const sql = `${URL_DB}SEL_USU_ROLES_SHOW_MENU(${a_usua_id},null,null)`
     axios.get(sql)
       .then((response) => {
          this.setState({
           accesos : response.data[0].filter((item) => { 
                                      return item.usma_orden.substr(0,2)==="03" &&  // 03-actividades
                                              item.usma_item!=="CAB" })
          },() => {
            this.setState({
              usma_item_def : this.state.accesos.length===0?'':this.state.accesos[0].usma_item
            })
          }) 
       })
       .catch((error) => console.log(error))       
  }
*/
    componentDidMount() {
        document.title = NOMBRE_SIST;
        // this.cargaAccesos(this.state.login_id);
    }

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        return (
            <div>
                <Container fluid="true">
                    <Tabs defaultActiveKey="home" id="tab-usuario">
                        <Tab eventKey="home" title="Cursos">
                            <DefCursos />
                        </Tab>
                        <Tab eventKey="tab2" title="Módulos">
                            <DefModulos />
                        </Tab>
                        <Tab eventKey="tab3" title="Relación Módulos-Sectores">
                            <ModuloSector />
                        </Tab>
                        <Tab eventKey="tab4" title="Consulta Cursos">
                            <ConsCursos />
                        </Tab>
                        <Tab eventKey="tab5" title="Log de Tablas">
                            <TablasLog />
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        );
    }
}

export default TablasCursosPage;
