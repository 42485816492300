import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Modal
} from "react-bootstrap";
//import swal from "sweetalert";
import { FaPlay, FaFastForward, FaFastBackward } from "react-icons/fa";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player";
import "../stylePages.css";

class Videos_Tutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tper_id: 2,
            explica: "T",
            registros: [],
            topicos: [],
            personaTipo: [],
            cur_fila: 0,
            vidPlayed: 0,

            filterGrilla: "",
            login_usua_id: sessionStorage.getItem("USUARIO"),
            primera_vez: true,
            opc_filtro_show: 0, // luego lo cambia perfil del usuario

            showModal: false,
            showModalModi: false,
            ctop_curs_id: 0,
            hay_ant: true,
            ctop_orden_ant: 0,
            hay_prox: true,
            ctop_orden_prox: 0,
            perfiles: [],
            links_video: [],
            video_porcen: 0,

            fetchRegistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
    }

    async getGrilla() {
        const sql = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${this.state.tper_id},${this.state.login_usua_id},'C')`;
        axios
            .get(sql)
            .then(response => {
                // Puede pasar que tengo un perfil que no tiene curso, en ese caso pongo un 0
                let opc_filtro_show = this.state.opc_filtro_show;
                if (this.state.primera_vez) {
                    const checkUsername = obj =>
                        obj.perf_id === opc_filtro_show;
                    if (!response.data[0].some(checkUsername)) {
                        opc_filtro_show = 0;
                    }
                }

                // filtro los reg dependiendo del perfil o no
                let registros = [];
                console.log("opc_filtro_show", opc_filtro_show);
                if (opc_filtro_show > 0) {
                    // puede venir el valor #3# o una lista #3#4#
                    registros = response.data[0].filter(e => {
                        // return Number(e.perf_id) === Number(opc_filtro_show);
                        return e.perf_id_lista.includes(opc_filtro_show);
                    });
                } else {
                    registros =
                        Number(opc_filtro_show) === Number(0)
                            ? response.data[0]
                            : response.data[0].filter(e => {
                                  return e.cuas_id !== null;
                              });
                }
                let perfiles_all = [];
                response.data[0].forEach(perf => {
                    let reg = perf.perf_regis.split("#");
                    reg.forEach(ele => {
                        let elem = ele.split("=");
                        let obj = {};
                        obj.perf_id = elem[0];
                        obj.perf_deno = elem[1];
                        perfiles_all.push(obj);
                    });
                });
                // distinct y ordeno
                let perfiles = perfiles_all
                    .filter(
                        (item, index, self) =>
                            index ===
                            self.findIndex(i => i.perf_deno === item.perf_deno)
                    )
                    .sort((a, b) => a.perf_deno.localeCompare(b.perf_deno));
                this.setState({
                    registros,
                    opc_filtro_show,
                    primera_vez: false,
                    perfiles
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });

        const sql2 = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${this.state.tper_id},${this.state.login_usua_id},'D')`;
        axios
            .get(sql2)
            .then(response => {
                this.setState({
                    topicos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD_PERM(${this.state.login_usua_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }

    async getUsuarioLogin() {
        const sql = `${URL_DB}SEL_USUARIOS(${this.state.login_usua_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        opc_filtro_show:
                            response.data[0][0].usua_perf_id === null
                                ? 0
                                : response.data[0][0].usua_perf_id,
                        tper_id: response.data[0][0].usua_tper_id
                    },
                    () => {
                        // console.log("cl", this.state.tper_id, response.data[0]);
                        this.getGrilla();
                    }
                );
            })
            .catch(error => console.log(error));
    }
    async getInicio() {
        try {
            await Promise.all([this.getPersonaTipo(), this.getUsuarioLogin()]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/

    handleChange = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    filtrarDatos = () => {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.curs_deno) ||
                regex.test(filtro.simo_deno) ||
                regex.test(filtro.perf_deno)
        );
    };

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    cargoProxAnterior = (a_curs_id, a_ctop_orden) => {
        let ctop_orden_prox = 0;
        let ctop_orden_ant = 0;
        let topicosCur = this.state.topicos.filter(
            e => Number(e.ctop_curs_id) === Number(a_curs_id)
        );

        const row_ant = topicosCur.filter(e => {
            return e.ctop_orden < a_ctop_orden;
        });
        if (row_ant) {
            if (row_ant.length > 0) {
                ctop_orden_ant = row_ant[row_ant.length - 1].ctop_orden;
            }
        }

        const row_prox = topicosCur.filter(e => {
            return e.ctop_orden > a_ctop_orden;
        })[0];
        if (row_prox) {
            ctop_orden_prox = row_prox.ctop_orden;
        }

        this.setState({
            ctop_curs_id: a_curs_id,
            hay_ant: ctop_orden_ant === 0 ? false : true,
            ctop_orden_ant,
            hay_prox: ctop_orden_prox === 0 ? false : true,
            ctop_orden_prox
        });
    };

    cargoVideo = (a_reg, a_deta) => {
        this.setState(
            {
                showModal: true,
                a_curs_id: a_reg.curs_id,
                a_curs_deno: a_reg.curs_deno,
                a_simo_deno: a_reg.simo_deno,
                a_ctop_orden: a_deta.ctop_orden,
                a_ctop_id: a_deta.ctop_id,
                a_ctop_deno: a_deta.ctop_deno,
                a_ctop_video: a_deta.ctop_video,
                a_ctop_img: a_deta.ctop_img,
                a_ctop_comentario: a_deta.ctuv_comentario
            },
            () => {
                this.cargoProxAnterior(a_reg.curs_id, a_deta.ctop_orden);
            }
        );
    };

    avanzaVideo = a_ctop_orden => {
        let a_ctop_deno = "";
        let a_ctop_video = "";
        let a_ctop_img = "";
        let row_orden = this.state.topicos.filter(e => {
            return (
                e.ctop_curs_id === this.state.ctop_curs_id &&
                e.ctop_orden === a_ctop_orden
            );
        });

        if (row_orden) {
            a_ctop_deno = row_orden[0].ctop_deno;
            a_ctop_video = row_orden[0].ctop_video;
            a_ctop_img = row_orden[0].ctop_img;
        }

        this.setState(
            {
                a_ctop_orden,
                a_ctop_deno,
                a_ctop_video,
                a_ctop_img
            },
            () => {
                this.cargoProxAnterior(this.state.ctop_curs_id, a_ctop_orden);
            }
        );
    };

    toggleModal = () => {
        this.setState({ showModalModi: !this.state.showModalModi });
        // refresh de panel
        this.getGrilla();
    };

    /*==========================================================================
        Actualizacion : reg de video y comentarios
    *==========================================================================*/
    regLogVideo = (a_ctop_id, a_accion, a_porcen, a_texto) => {
        // a_accion = Video , Texto

        if (a_texto === "" && a_accion === "T") {
            this.setState({ a_ctop_comentario: "" });
        }

        if (a_accion === "V" && a_porcen === null) {
            // cerro la ventana antes de terminar el video
            a_porcen = parseInt(Number(this.state.vidPlayed) * 100);
        }
        if (a_accion === "V" && a_porcen === 0) {
            // recien entra
            this.setState({ vidPlayed: 0 });
        }

        const sql = `${URL_DB}AM_LOG_VIDEO(${this.state.login_usua_id},${a_ctop_id},'${a_accion}',${a_porcen},'${a_texto}')`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        respuestaSp: response.data[0][0].respuesta
                    } /*,
                    () => {
                        if (this.state.respuestaSp === "OK") {
                            this.setState({
                                mensajeAlerta: "Registrado correctamente",
                                mensajeColor: "green",
                                respError: ""
                            });
                        } else {
                            this.setState({
                                respError: this.state.respuestaSp
                            });
                        }
                    }*/
                );
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error);
            });
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col xs={3} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Cursos por perfil</b>
                            </h2>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <select
                                    className="form-control"
                                    name="simo_id"
                                    value={this.state.tper_id}
                                    style={{ fontWeight: "bold" }}
                                    size={"lg"}
                                    onChange={e => {
                                        this.setState(
                                            {
                                                tper_id: e.target.value
                                            },
                                            () => {
                                                this.getGrilla();
                                            }
                                        );
                                    }}
                                >
                                    {this.state.personaTipo.map(mod => {
                                        return (
                                            <option
                                                key={mod.value}
                                                value={mod.value}
                                            >
                                                {mod.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <select
                                className="form-control"
                                name="opc_filtro_show"
                                value={this.state.opc_filtro_show}
                                style={{ fontWeight: "bold" }}
                                onChange={e => {
                                    this.setState(
                                        {
                                            opc_filtro_show: e.target.value
                                        },
                                        () => {
                                            this.getGrilla();
                                        }
                                    );
                                }}
                            >
                                <optgroup label="Todos los perfiles">
                                    <option key={10000} value={Number(-1)}>
                                        Solo mis cursos
                                    </option>
                                    <option key={10001} value={0}>
                                        Todos los cursos
                                    </option>
                                </optgroup>
                                <optgroup label="Por perfil">
                                    {this.state.perfiles.map(perf => {
                                        return (
                                            <option
                                                key={perf.perf_id}
                                                value={perf.perf_id}
                                            >
                                                {perf.perf_deno}
                                            </option>
                                        );
                                    })}
                                </optgroup>
                            </select>
                        </Col>

                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar en esta grilla"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table bordered hover size="sm" id="data_table">
                                <thead className="Grilla-header">
                                    <tr key={10000}>
                                        <th>#Id</th>
                                        <th>Curso</th>
                                        <th>Módulo</th>
                                        <th>Perfil Usuario</th>
                                        <th>Tópico</th>
                                        <th>Asignado por</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}

                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    backgroundColor: regis.color
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        width: "5%",
                                                        textAlign: "center",
                                                        backgroundColor:
                                                            regis.color_row
                                                    }}
                                                >
                                                    {regis.curs_id}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "15%"
                                                    }}
                                                >
                                                    {regis.curs_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        backgroundColor:
                                                            regis.simo_color
                                                    }}
                                                >
                                                    {regis.simo_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "10%"
                                                    }}
                                                >
                                                    {regis.perf_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "55%"
                                                    }}
                                                >
                                                    {this.state.topicos
                                                        .filter(e => {
                                                            return (
                                                                e.ctop_curs_id ===
                                                                regis.curs_id
                                                            );
                                                        })
                                                        .map((deta, i) => {
                                                            return (
                                                                <tr
                                                                    key={
                                                                        10000 +
                                                                        i
                                                                    }
                                                                >
                                                                    <td
                                                                        style={{
                                                                            width: "30px",
                                                                            textAlign:
                                                                                "center"
                                                                        }}
                                                                    >
                                                                        {
                                                                            deta.ctop_orden
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        style={{
                                                                            width: "450px"
                                                                        }}
                                                                    >
                                                                        {
                                                                            deta.ctop_deno
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "60px%",
                                                                            textAlign:
                                                                                "left"
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant={
                                                                                deta.ctop_video !==
                                                                                null
                                                                                    ? "success"
                                                                                    : "danger"
                                                                            }
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                this.cargoVideo(
                                                                                    regis,
                                                                                    deta
                                                                                )
                                                                            }
                                                                        >
                                                                            <FaPlay />
                                                                        </Button>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "10px"
                                                                        }}
                                                                    ></td>
                                                                    <td
                                                                        style={{
                                                                            width: "100px"
                                                                        }}
                                                                    >
                                                                        <div className="progress">
                                                                            <div
                                                                                className="progress-bar progress-bar-striped bg-info"
                                                                                role="progressbar"
                                                                                style={{
                                                                                    width:
                                                                                        deta.ctuv_vista_porcen +
                                                                                        "%"
                                                                                }}
                                                                                aria-valuenow={
                                                                                    deta.ctuv_vista_porcen
                                                                                }
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100"
                                                                            >
                                                                                {String(
                                                                                    deta.ctuv_vista_porcen
                                                                                ) +
                                                                                    "%"}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "70px",
                                                                            textAlign:
                                                                                "center"
                                                                        }}
                                                                    >
                                                                        {
                                                                            deta.ctuv_fecha
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </td>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.color_row
                                                    }}
                                                >
                                                    {regis.usuario_asignador}{" "}
                                                    {regis.cuas_fecha}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                {registrosFiltrados.length > 0 && (
                                    <tfoot className="Grilla-header">
                                        <tr>
                                            <td style={{ textAlign: "center" }}>
                                                {`${registrosFiltrados.length} Reg`}
                                            </td>
                                            <td colSpan={4} />
                                        </tr>
                                    </tfoot>
                                )}
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({ showModal: false });
                        this.regLogVideo(this.state.a_ctop_id, "V", null, "");
                        this.getGrilla();
                    }}
                    dialogClassName="modal-90"
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#00cc99" }}
                    >
                        <Modal.Title>{`Tópico: ${this.state.a_ctop_orden} - ${this.state.a_ctop_deno}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={10}>
                                    {`${this.state.a_curs_deno}/${this.state.a_simo_deno}`}
                                </Col>
                                <Col md={2}>
                                    {this.state.hay_ant && (
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            style={{
                                                textAlign: "left"
                                            }}
                                            onClick={() =>
                                                this.avanzaVideo(
                                                    this.state.ctop_orden_ant
                                                )
                                            }
                                        >
                                            <FaFastBackward />
                                            {`
                                        ${this.state.ctop_orden_ant}`}
                                        </Button>
                                    )}
                                    {this.state.hay_prox && (
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            style={{
                                                textAlign: "left"
                                            }}
                                            onClick={() =>
                                                this.avanzaVideo(
                                                    this.state.ctop_orden_prox
                                                )
                                            }
                                        >
                                            <FaFastForward />
                                            {`
                                        ${this.state.ctop_orden_prox}`}
                                        </Button>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                    >
                                        <Tab eventKey="home" title="Video">
                                            <Row>
                                                <Col>
                                                    <div className="player-wrapper">
                                                        <ReactPlayer
                                                            height={"480px"}
                                                            width={"780px"}
                                                            playing={true}
                                                            controls
                                                            url={
                                                                this.state
                                                                    .a_ctop_video
                                                            }
                                                            // disable right click
                                                            onContextMenu={e =>
                                                                e.preventDefault()
                                                            }
                                                            onReady={() =>
                                                                console.log(
                                                                    "Onready"
                                                                )
                                                            }
                                                            onStart={() =>
                                                                this.regLogVideo(
                                                                    this.state
                                                                        .a_ctop_id,
                                                                    "V",
                                                                    0,
                                                                    ""
                                                                )
                                                            }
                                                            onProgress={progress => {
                                                                this.setState({
                                                                    vidPlayed:
                                                                        progress.played
                                                                });
                                                            }}
                                                            onPlay={console.log()}
                                                            onPause={() =>
                                                                console.log(
                                                                    "onPause"
                                                                )
                                                            }
                                                            onEnded={() =>
                                                                this.regLogVideo(
                                                                    this.state
                                                                        .a_ctop_id,
                                                                    "V",
                                                                    100,
                                                                    ""
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <b>
                                                                    Comentario
                                                                    interno
                                                                </b>
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={5}
                                                                name="a_ctop_comentario"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .a_ctop_comentario
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            onClick={() => {
                                                                this.regLogVideo(
                                                                    this.state
                                                                        .a_ctop_id,
                                                                    "T",
                                                                    100,
                                                                    this.state
                                                                        .a_ctop_comentario
                                                                );
                                                            }}
                                                        >
                                                            Grabar Comentario
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => {
                                                                this.regLogVideo(
                                                                    this.state
                                                                        .a_ctop_id,
                                                                    "T",
                                                                    100,
                                                                    ""
                                                                );
                                                            }}
                                                        >
                                                            Borrar Comentario
                                                        </Button>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {this.state.a_ctop_img !== null && (
                                            <Tab
                                                eventKey="tab3"
                                                title={"Infografía"}
                                            >
                                                <div className="infografia">
                                                    <Image
                                                        src={
                                                            this.state
                                                                .a_ctop_img
                                                        }
                                                        fluid
                                                    />
                                                </div>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={12}
                                    style={{
                                        height: "10px"
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "8px",
                                            color: "grey",
                                            marginBottom: "0"
                                        }}
                                    >{`src vid= ${this.state.a_ctop_video} src inf= ${this.state.a_ctop_img} cod=${this.state.a_ctop_id}`}</p>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Videos_Tutorial;
