import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { NOMBRE_SIST } from "./../../constants";

function exportToCSV(a_titulo, a_fileName, a_sqlGrilla) {
    var today = new Date();
    const hoy = `${today.getDate()}/${
        today.getMonth() + 1 < 10
            ? `0${today.getMonth() + 1}`
            : `${today.getMonth() + 1}`
    }/${today.getFullYear()}`;
    const fileExtension = ".xlsx";

    /* ========= Obtengo cabeceras ===============*/
    /*
    const sqlColumnas = a_sqlGrilla.replace("('D'", "('T'");
    axios
        .get(sqlColumnas)
        .then(response => {
            var regColumnas = response.data[0];
            var colName = [],
                colTitulo = [],
                colAncho = [],
                colAlign = [];
            for (var ind = 0; ind < regColumnas.length; ind++) {
                var rObj = regColumnas[ind];
                colName.push(rObj.col);
                colTitulo.push(rObj.nombre);
                colAncho.push(rObj.pix);
                colAlign.push(rObj.align);
            }
            console.log(regColumnas);
            console.log(colName);
            console.log(colTitulo);
            console.log(colAncho);
            console.log(colAlign);
*/
    let colTitulo = [
        "Módulo",
        "T.Personal",
        "Perfil",
        "Nombre del curso",
        "#Ord",
        "Tópico",
        "Archivo de video",
        "Archivo de imagen",
        "F.alta top",
        "#Id Curso",
        "#Id Topico"
    ];
    /* =========     Obtengo datos ===============*/
    axios
        .get(a_sqlGrilla)
        .then(response => {
            let registros = response.data[0];

            // Corregir este objeto: parche -- solo debo 'excelear' solo los que defini titulo
            console.log(registros);

            var Heading = [[`${NOMBRE_SIST} : ${hoy} `], [`${a_titulo}`]];

            Heading.push(colTitulo);
            var Headcol = []; //desplaza columnas
            var ws = XLSX.utils.aoa_to_sheet(Heading);

            XLSX.utils.sheet_add_json(ws, registros, {
                header: Headcol,
                skipHeader: true,
                origin: -1
            });
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
            var wbout = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array"
            });
            FileSaver.saveAs(
                new Blob([wbout], { type: "application/octet-stream" }),
                a_fileName + fileExtension
            );

            /* const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: this.state.fileType});
            //FileSaver.saveAs(data, fileName + this.state.fileExtension); */
        })
        .catch(error => {
            alert("ERROR interno API al exportar datos BD:" + error);
        });
    /*      })
        .catch(error => {
            alert("ERROR interno API al exportar titulos columnas BD:" + error);
        });
        */
}

export default exportToCSV;
