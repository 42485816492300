import React from "react";
import { URL_DB } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Modal,
    Tabs,
    Tab,
    Image
} from "react-bootstrap";
import exportToCSV from "../../utils/functions/export-excel";
import { FaPlay, FaDownload } from "react-icons/fa";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import ReactPlayer from "react-player";
import "../../pages/stylePages.css";

class ConsCursos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sedes: [],
            login_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            simo_id: 0,
            simo_deno: "",
            simo_sede_id: 0,
            filterGrilla: "",
            showModalPreview: false,
            fetchRegistros: false,
            expSql: "",
            expTitulo: "Detalle de cursos",
            expSubtit: "esqueleto con archivos adjuntos",
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }

    async getCursos() {
        const sql = `${URL_DB}SEL_CURSOS_ESQUELETO(1)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getCursos()]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.simo_deno) || regex.test(filtro.sede_deno)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleModalPreview = (a_video, a_imagen) => {
        this.setState({
            showModalPreview: true,
            video_topico_prev: a_video,
            imagen_topico_prev: a_imagen
        });
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        const registrosFiltrados = this.filtrarDatos();
        // const respError = this.state.respError;
        //const mensajeAlerta = this.state.mensajeAlerta;
        //const mensajeColor = this.state.mensajeColor;

        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ fontSize: "22px" }}>
                            <b>Consulta estructura de cursos</b>
                        </Col>

                        <Col xs={1}>
                            {/*
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                    this.setState({ showhelp: true });
                                }}
                            >
                                Ayuda
                            </Button>
                            */}
                        </Col>
                        <Col xs={2}>
                            {this.state.registros.length > 0 && (
                                <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={e =>
                                        exportToCSV(
                                            this.state.expTitulo,
                                            this.state.expSubtit,
                                            `${URL_DB}SEL_CURSOS_ESQUELETO(1)`
                                        )
                                    }
                                >
                                    <FaDownload /> Excel
                                </Button>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_curs_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "simo_deno"
                                                    )
                                                }
                                            >
                                                Módulo
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_orden_simo"
                                                    )
                                                }
                                            >
                                                Ord curso
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "tper_deno"
                                                    )
                                                }
                                            >
                                                T/Personal
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Perfil
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_deno"
                                                    )
                                                }
                                            >
                                                Demoninación curso
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_orden"
                                                    )
                                                }
                                            >
                                                Orden
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_deno"
                                                    )
                                                }
                                            >
                                                Tópico
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_video"
                                                    )
                                                }
                                            >
                                                Video
                                            </Button>
                                        </th>

                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_img"
                                                    )
                                                }
                                            >
                                                Imagen
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_alta_f"
                                                    )
                                                }
                                            >
                                                Alta
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_id"
                                                    )
                                                }
                                            >
                                                #ctop_id
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{regis.ctop_curs_id}</td>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.simo_color
                                                    }}
                                                >
                                                    {regis.simo_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.simo_color,
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.curs_orden_simo}
                                                </td>
                                                <td>{regis.tper_deno}</td>
                                                <td>{regis.perf_deno}</td>
                                                <td>{regis.curs_deno}</td>
                                                <td>{regis.ctop_orden}</td>
                                                <td>{regis.ctop_deno}</td>
                                                <td>{regis.ctop_video}</td>
                                                <td>{regis.ctop_img}</td>
                                                <td>{regis.ctop_alta_f}</td>
                                                <td>{regis.ctop_id}</td>
                                                <td>
                                                    <Button
                                                        variant={
                                                            regis.ctop_video !==
                                                            null
                                                                ? "success"
                                                                : "danger"
                                                        }
                                                        size="sm"
                                                        onClick={() => {
                                                            this.handleModalPreview(
                                                                regis.ctop_video,
                                                                regis.ctop_img
                                                            );
                                                        }}
                                                    >
                                                        <FaPlay />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModalPreview}
                    onHide={() => this.setState({ showModalPreview: false })}
                    dialogClassName="modal-50"
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#b3d9ff" }}
                    >
                        <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="home" title="Video">
                                <div className="player-wrapper">
                                    <ReactPlayer
                                        height={"360px"}
                                        width={"585px"}
                                        playing={true}
                                        controls
                                        url={this.state.video_topico_prev}
                                        // disable right click
                                        onContextMenu={e => e.preventDefault()}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="tab3" title={"Infografía"}>
                                <div className="infografia">
                                    <Image
                                        src={this.state.imagen_topico_prev}
                                        fluid
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ConsCursos;
