import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import { Table, Row, Col, Container, Form } from "react-bootstrap";
//import { FaTimes, FaCheck } from "react-icons/fa";
//import { BsFillRecordFill } from "react-icons/bs";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
// import DetaCursoVistas from "../../../src/components/DetaCursoVistas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../stylePages.css";

class CuadroVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sect_id: 0,
            curs_id: 0,
            simo_id: 1,
            explica: "T",
            user_curso: 0,
            registros: [],
            cursosLista: [],
            resumen: [],
            resumenFull: [],
            resumCursosLista: [],
            cuadroFull: [],
            cuadro: [],
            modulos: [],
            sectores: [],
            cur_fila: 0,
            autosuggestValue: "",
            filterGrilla: "",
            login_usua_id: sessionStorage.getItem("USUARIO"),
            fetchRegistros: false,
            registrosFiltrados: [],
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
    }

    async getGrilla() {
        const sql = `${URL_DB}SEL_CURSOS_USUARIOS_CUADRO(${this.state.simo_id},'D')`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        registros: response.data[0]
                    },
                    () => {
                        this.armaData(this.state.registros);
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });
    }

    async getResumen() {
        const sql = `${URL_DB}SEL_CURSOS_USUARIOS_CUADRO(${this.state.simo_id},'R')`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        resumen: response.data[0]
                    },
                    () => {
                        this.armaResumen(this.state.resumen);
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });
    }

    async getModulo() {
        const sql = `${URL_DB}SEL_MODULO_DD(1)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    modulos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }
    async getSectores() {
        const sql = `${URL_DB}SEL_USUA_SEDE_SECTOR_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    sectores: response.data[0],
                    sect_id: response.data[0][0].sect_id
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([
                this.getSectores(),
                this.getModulo(),
                this.getGrilla(),
                this.getResumen()
            ]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/

    filtrarDatos = () => {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.uso_deno) || regex.test(filtro.tema_deno)
        );
    };

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    armaData = a_reg => {
        let cursosLista = a_reg.filter(f => f.usua_id === a_reg[0].usua_id);
        let priCur = cursosLista[0].curs_id;
        let ultCur = cursosLista[cursosLista.length - 1].curs_id;

        let cuadroFull = [];
        let obj = {};
        let reg = {};
        for (let i = 0; i < a_reg.length; i++) {
            obj = {};
            obj.conteni = a_reg[i].conteni;
            obj.color = a_reg[i].color;

            if (a_reg[i].curs_id === priCur) {
                reg = {};
                reg.usua_id = a_reg[i].usua_id;
                reg.apenom = a_reg[i].apenom;
                reg.usua_usuario = a_reg[i].usua_usuario;
                reg.usua_sect_id = a_reg[i].usua_sect_id;

                reg.cursos = []; // Empiezo a armar el vector de cero cursos para ese usuario
            }
            reg.cursos.push(obj);

            if (a_reg[i].curs_id === ultCur) {
                cuadroFull.push(reg);
            }
        }

        this.setState({
            cuadroFull,
            cuadro: cuadroFull.filter(
                f => f.usua_sect_id === this.state.sect_id
            ),
            cursosLista
        });
    };

    armaResumen = a_reg => {
        let resumCursosLista = a_reg.filter(
            f => f.usua_sect_id === a_reg[0].usua_sect_id
        );
        let priCur = resumCursosLista[0].curs_id;
        let ultCur = resumCursosLista[resumCursosLista.length - 1].curs_id;

        let resumenFull = [];
        let obj = {};
        let reg = {};
        for (let i = 0; i < a_reg.length; i++) {
            obj = {};
            obj.conteni = a_reg[i].conteni;
            obj.color = a_reg[i].color;

            if (a_reg[i].curs_id === priCur) {
                reg = {};
                reg.sect_id = a_reg[i].usua_sect_id;
                reg.sect_deno = a_reg[i].sect_deno;
                reg.sede_deno = a_reg[i].sede_deno;
                reg.cursos = []; // Empiezo a armar el vector de cero cursos para ese usuario
            }
            reg.cursos.push(obj);

            if (a_reg[i].curs_id === ultCur) {
                resumenFull.push(reg);
            }
        }

        this.setState({
            resumenFull,
            resumCursosLista
        });
    };
    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col xs={5} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Cuadro de vistas de cursos</b>
                            </h2>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <b>Seleccione Módulo</b>
                                <select
                                    className="form-control"
                                    name="simo_id"
                                    value={this.state.simo_id}
                                    onChange={e => {
                                        this.setState(
                                            {
                                                simo_id: e.target.value
                                            },
                                            () => {
                                                this.getGrilla();
                                                this.getResumen();
                                            }
                                        );
                                    }}
                                >
                                    {this.state.modulos.map(mod => {
                                        return (
                                            <option
                                                key={mod.simo_id}
                                                value={mod.simo_id}
                                            >
                                                {mod.simo_deno}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                            >
                                <Tab eventKey="home" title="Todos los usuarios">
                                    <Row>
                                        <Col md={5}>
                                            <Form.Group>
                                                <b>Seleccione sector</b>
                                                <select
                                                    className="form-control"
                                                    name="curs_id"
                                                    value={this.state.sect_id}
                                                    onChange={e => {
                                                        this.setState({
                                                            sect_id:
                                                                e.target.value,
                                                            cuadro:
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                ) === Number(-1)
                                                                    ? this.state
                                                                          .cuadroFull
                                                                    : this.state.cuadroFull.filter(
                                                                          r =>
                                                                              r.usua_sect_id ===
                                                                              Number(
                                                                                  e
                                                                                      .target
                                                                                      .value
                                                                              )
                                                                      )
                                                        });
                                                    }}
                                                >
                                                    {this.state.sectores.map(
                                                        sec => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        sec.sect_id
                                                                    }
                                                                    value={
                                                                        sec.sect_id
                                                                    }
                                                                >
                                                                    {sec.deno}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3} />
                                        <Col md={4}>
                                            <p
                                                style={{
                                                    fontSize: "10px",
                                                    color: "grey",
                                                    marginBottom: "0"
                                                }}
                                            >
                                                (*) En Celeste= Usuarios que
                                                vieron los videos sin tener
                                                cursos
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "10px",
                                                    color: "grey",
                                                    marginBottom: "0"
                                                }}
                                            >
                                                (*) En banco= Sin curso asignado
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table
                                                bordered
                                                hover
                                                size="sm"
                                                id="data_table"
                                            >
                                                <thead className="Grilla-header">
                                                    <tr key={10000}>
                                                        <th
                                                            style={{
                                                                width: "10px"
                                                            }}
                                                        >
                                                            #Id
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "100px"
                                                            }}
                                                        >
                                                            Apellido y nombre
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "100px"
                                                            }}
                                                        >
                                                            Usuario
                                                        </th>
                                                        {this.state.cursosLista.map(
                                                            colu => {
                                                                return (
                                                                    <th
                                                                        style={{
                                                                            width: "30px",
                                                                            textAlign:
                                                                                "center"
                                                                        }}
                                                                    >
                                                                        {`#${colu.curs_id}`}
                                                                    </th>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* this.state.registrosFiltrados &&
                                        "Cargando..."*/}

                                                    {this.state.cuadro.map(
                                                        (fila, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {
                                                                            fila.usua_id
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            fila.apenom
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            fila.usua_usuario
                                                                        }
                                                                    </td>
                                                                    {fila.cursos.map(
                                                                        col => (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        col.color,
                                                                                    textAlign:
                                                                                        "center"
                                                                                }}
                                                                            >
                                                                                {
                                                                                    col.conteni
                                                                                }
                                                                            </td>
                                                                        )
                                                                    )}
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                                {registrosFiltrados.length >
                                                    0 && (
                                                    <tfoot className="Grilla-header">
                                                        <tr key={100001}>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center"
                                                                }}
                                                            >{`${this.state.cuadro.length} Reg`}</td>
                                                            <td colSpan={7} />
                                                        </tr>
                                                    </tfoot>
                                                )}
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab
                                    eventKey="tab1"
                                    title="Sectores con cursos asignados"
                                >
                                    <Row>
                                        <Col>
                                            <Table
                                                bordered
                                                hover
                                                size="sm"
                                                id="data_table"
                                            >
                                                <thead className="Grilla-header">
                                                    <tr key={10000}>
                                                        <th
                                                            style={{
                                                                width: "10px"
                                                            }}
                                                        >
                                                            Sede
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "100px"
                                                            }}
                                                        >
                                                            Sector
                                                        </th>

                                                        {this.state.resumCursosLista.map(
                                                            colu => {
                                                                return (
                                                                    <th
                                                                        style={{
                                                                            width: "30px",
                                                                            textAlign:
                                                                                "center"
                                                                        }}
                                                                    >
                                                                        {`#${colu.curs_id}`}
                                                                    </th>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.resumenFull.map(
                                                        (fila, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {
                                                                            fila.sede_deno
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            fila.sect_deno
                                                                        }
                                                                    </td>
                                                                    {fila.cursos.map(
                                                                        col => (
                                                                            <td
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        col.color,
                                                                                    textAlign:
                                                                                        "center"
                                                                                }}
                                                                            >
                                                                                {
                                                                                    col.conteni
                                                                                }
                                                                            </td>
                                                                        )
                                                                    )}
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                                {this.state.resumenFull.length >
                                                    0 && (
                                                    <tfoot className="Grilla-header">
                                                        <tr key={100001}>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center"
                                                                }}
                                                            >{`${this.state.resumenFull.length} Reg`}</td>
                                                            <td colSpan={7} />
                                                        </tr>
                                                    </tfoot>
                                                )}
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col md={3}>
                            <Table bordered hover size="sm" id="data_table">
                                <thead>
                                    <tr key={10000}>
                                        <th>#</th>
                                        <th>Curso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cursosLista.map((cur, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{cur.curs_id}</td>
                                                <td>{cur.curs_deno}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CuadroVideo;
