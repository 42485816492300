import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Modal,
    Tabs,
    Tab,
    Image
} from "react-bootstrap";
//import swal from "sweetalert";
import { FaPlay } from "react-icons/fa";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
//import fileSaver from "file-saver";
//import StructuredFilter from 'react-structured-filter';
//import Select from "react-select";
import ReactPlayer from "react-player";
//import exportToCSV from "../../utils/functions/export-excel";
import "../stylePages.css";
//import { Howl } from 'howler'

class Img_Tutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tper_id: 0,
            registros: [],
            personaTipo: [],
            filterGrilla: "",
            login_usua_id: sessionStorage.getItem("USUARIO"),

            showModal: false,
            tema_deno: "",
            ctop_video: "",
            ctop_img: "",

            fetchRegistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
    }

    async getGrilla() {
        const sql = `${URL_DB}SEL_TEMA_VIDEO(${this.state.tper_id},'I',null)`;
        console.log(sql);
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });
    }

    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD_PERM(${this.state.login_usua_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getPersonaTipo()]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos = () => {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.simo_deno) ||
                regex.test(filtro.perf_deno) ||
                regex.test(filtro.deno) ||
                regex.test(filtro.curs_id)
        );
    };

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    cargoVideo = a_reg => {
        this.setState({
            showModal: true,
            tema_deno: a_reg.deno,
            ctop_video: a_reg.ctop_video,
            ctop_img: a_reg.ctop_img
        });
    };
    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Infografias Tutoriales</b>
                            </h2>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <select
                                    className="form-control"
                                    name="tper_id"
                                    value={this.state.tper_id}
                                    style={{ fontWeight: "bold" }}
                                    onChange={e => {
                                        this.setState(
                                            {
                                                tper_id: e.target.value
                                            },
                                            () => {
                                                this.getGrilla();
                                            }
                                        );
                                    }}
                                >
                                    <option key={"0"} value={0} disabled>
                                        ( Seleccione Tipo personal )
                                    </option>
                                    {this.state.personaTipo.map(mod => {
                                        return (
                                            <option
                                                key={mod.value}
                                                value={mod.value}
                                            >
                                                {mod.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar en esta grilla"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_id"
                                                    )
                                                }
                                            >
                                                #Curso
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "simo_deno"
                                                    )
                                                }
                                            >
                                                Módulo
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Perfil
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("deno")
                                                }
                                            >
                                                Tema
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ctop_orden"
                                                    )
                                                }
                                            >
                                                Ord/Curso
                                            </Button>
                                        </th>

                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td
                                                    style={{
                                                        width: "50px",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.curs_id}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "100px",
                                                        backgroundColor:
                                                            regis.simo_color
                                                    }}
                                                >
                                                    {regis.simo_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70px"
                                                    }}
                                                >
                                                    {regis.perf_deno}
                                                </td>
                                                <td>{regis.deno}</td>
                                                <td>{regis.ctop_orden}</td>
                                                <td>
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.cargoVideo(
                                                                regis
                                                            )
                                                        }
                                                    >
                                                        <FaPlay />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                {registrosFiltrados.length > 0 && (
                                    <tfoot className="Grilla-header">
                                        <tr>
                                            <td
                                                style={{ textAlign: "center" }}
                                            >{`${registrosFiltrados.length} Reg`}</td>
                                            <td colSpan={4} />
                                        </tr>
                                    </tfoot>
                                )}
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({ showModal: false });
                    }}
                    dialogClassName="modal-60"
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#00cc99" }}
                    >
                        <Modal.Title>{`Tema: ${this.state.tema_deno}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                            >
                                <Tab eventKey="home" title={"Infografía"}>
                                    <div className="infografia">
                                        <Image
                                            src={this.state.ctop_img}
                                            fluid
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="opcion_1" title="Video">
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            height={"480px"}
                                            width={"780px"}
                                            playing={true}
                                            controls
                                            url={this.state.ctop_video}
                                            // disable right click
                                            onContextMenu={e =>
                                                e.preventDefault()
                                            }
                                            onReady={() =>
                                                console.log("Onready")
                                            }
                                            onStart={() =>
                                                console.log("onStart")
                                            }
                                            onPause={() =>
                                                console.log("onPause")
                                            }
                                            onEnded={() =>
                                                console.log("onEnded")
                                            }
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Img_Tutorial;
