import React from "react";
import { URL_DB } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Alert,
    Modal,
    FormGroup,
    Tabs,
    Tab,
    Image
} from "react-bootstrap";
// import exportToCSV from "../../utils/functions/export-excel";
import { FaRegEdit, FaTrashAlt, FaPlay } from "react-icons/fa";
import { CgInsertAfterR } from "react-icons/cg";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import Select from "react-select";
import ReactPlayer from "react-player";
import swal from "sweetalert";
import "../../pages/stylePages.css";

class DefCursos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            modulos: [],
            personaTipo: [],
            perfiles: [],
            topicos: [],
            selectedOption: [],
            login_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            curs_id: 0,
            curs_deno: "",
            curs_tper_id: 0,
            curs_perf_id: 0,
            ctop_id_new: 0,
            video_topico_prev: "",
            imagen_topico_prev: "",
            filterGrilla: "",
            showModalEdit: false,
            fetchRegistros: false,
            showModalPreview: false,
            expSql: "",
            expTitulo: "Reporte de Sectores",
            expSubtit: "",
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }

    async getCursos() {
        const sql = `${URL_DB}SEL_SIST_CURSO(1)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getModulos() {
        const sql = `${URL_DB}SEL_MODULO_DD(1)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    modulos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }

    async getPerfiles() {
        const sql = `${URL_DB}SEL_USUA_PERFIL(null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    perfiles: response.data[0].map(item => ({
                        value: item.perf_id,
                        label: item.perf_deno
                    }))
                });
            })
            .catch(error => console.log(error));
    }

    async getTopicos(a_curs_id) {
        const sql = `${URL_DB}SEL_CURSO_TOPICO(${a_curs_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        topicos: response.data[0]
                    },
                    () => {
                        if (this.state.topicos.length === 0) {
                            this.insertItem(0);
                        }
                    }
                );
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([
                this.getCursos(),
                this.getModulos(),
                this.getPersonaTipo(),
                this.getPerfiles()
            ]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.curs_deno) || regex.test(filtro.sede_deno)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChange = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value.replace("/", "-").replace("'", '"')
        });
    };

    handleChangePerfiles = selectedOption => {
        this.setState({ selectedOption });
    };

    handleModalEdit = regis => {
        let selectedOption = [];
        let regi = regis.perf_id_lista.split("#");
        regi.forEach(reg => {
            let elem = reg.split("=");
            let obj = {};
            obj.value = Number(elem[0]);
            obj.label = elem[1];
            selectedOption.push(obj);
        });

        this.setState({
            showModalEdit: true,
            curs_id: regis.curs_id,
            curs_deno: regis.curs_deno,
            curs_simo_id: regis.curs_simo_id,
            curs_orden_simo: regis.curs_orden_simo,
            curs_tper_id: regis.curs_tper_id,
            selectedOption,
            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
        this.getTopicos(regis.curs_id);
    };

    handleModalAlta = () => {
        this.setState({
            showModalEdit: true,
            curs_id: 0,
            curs_deno: "",
            curs_simo_id: 0,
            curs_orden_simo: 0,
            curs_tper_id: 0,
            // curs_perf_id: 0,
            selectedOption: [],
            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
        this.insertItem(0);
    };

    handleModalExit = () => {
        this.setState({ showModalEdit: false });
        this.setState({
            mensajeAlerta: "No se aplicaron cambios",
            mensajeColor: "red"
        });
    };

    handleModalPreview = (a_video, a_imagen) => {
        this.setState({
            showModalPreview: true,
            video_topico_prev: a_video,
            imagen_topico_prev: a_imagen
        });
    };

    /*==========================================================================
        MODAL : filas de la grilla de referentes 
    *==========================================================================*/
    handleChangeItem(event, a_fila) {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let topicos = this.state.topicos;

        if (name === "ctop_orden") {
            topicos[a_fila].ctop_orden = value;
        }
        if (name === "ctop_deno") {
            topicos[a_fila].ctop_deno = value;
        }
        if (name === "ctop_video") {
            topicos[a_fila].ctop_video = value;
        }
        if (name === "ctop_img") {
            topicos[a_fila].ctop_img = value;
        }

        this.setState({ topicos });
    }

    insertItem(a_fila) {
        let topicos = this.state.topicos;
        let ctop_id_new = Number(this.state.ctop_id_new) + 1;

        let obj = {};
        obj.ctop_id = Number(ctop_id_new) * -1;
        obj.ctop_orden = Number(0);
        obj.ctop_curs_id = 0;
        obj.ctop_deno = "";
        obj.ctop_video = "";
        obj.ctop_img = "";

        topicos.splice(a_fila + 1, 0, obj);

        for (let i = a_fila; i < topicos.length; i++) {
            topicos[i].ctop_orden = Number(i) + 1;
        }

        this.setState({ topicos, ctop_id_new });
    }

    borraItem(a_ctop_id, a_deno) {
        if (a_ctop_id > 0) {
            swal({
                title: `Eliminar tópico ${a_deno}`,
                text: "Luego de grabar se elimina definitivamente, confirma ?",
                icon: "warning",
                buttons: ["No", "Si"]
            }).then(respuesta => {
                if (respuesta === true) {
                    this.setState(
                        {
                            topicos: this.state.topicos.filter(function (item) {
                                return item.ctop_id !== a_ctop_id;
                            })
                        },
                        () => {
                            if (this.state.topicos.length === 0) {
                                this.insertItem(0);
                            }
                        }
                    );
                }
            });
        } else {
            // si no existia el registro... ni  pregunto
            this.setState(
                {
                    topicos: this.state.topicos.filter(function (item) {
                        return item.ctop_id !== a_ctop_id;
                    })
                },
                () => {
                    if (this.state.topicos.length === 0) {
                        this.insertItem(0);
                    }
                }
            );
        }
    }

    /*==========================================================================
        Actualizacion : Modificacion a base de datos
    *==========================================================================*/

    handleGrabar = e => {
        let perfiles = this.state.selectedOption.map(e => {
            let obj = {};
            obj.perf_if = e.value;
            return obj;
        });

        let topico = this.state.topicos.map(elem => {
            let obj = {};
            obj.ctop_id = elem.ctop_id;
            obj.ctop_orden = elem.ctop_orden;
            obj.ctop_deno = encodeURIComponent(elem.ctop_deno);
            obj.ctop_video = elem.ctop_video;
            obj.ctop_img = elem.ctop_img;
            return obj;
        });

        const sql = `${URL_DB}AM_SIST_CURSO(${this.state.login_id},1,${
            this.state.curs_id
        },'${encodeURIComponent(this.state.curs_deno)}',${
            this.state.curs_simo_id
        }, ${this.state.curs_orden_simo},${this.state.curs_tper_id},'${String(
            JSON.stringify(perfiles)
        )}','${String(JSON.stringify(topico))}')`;
        //console.log(sql);
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalEdit: false
                    });
                    this.getCursos();
                }
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error + sql);
            });
    };

    handleEliminar = a_reg => {
        swal({
            title: `Baja de ${a_reg.curs_deno} `,
            text: `Para confirmar escriba la palabra:"BAJA"`,
            content: "input",
            button: {
                text: "Graba",
                closeModal: true
            }
        }).then(result => {
            if (result === "BAJA") {
                const sql = `${URL_DB}B_USUA_SEDE_SECTOR(${this.state.login_id},'${a_reg.curs_id}')`;
                axios
                    .get(sql)
                    .then(response => {
                        this.setState(
                            {
                                respuestaSp: response.data[0][0].respuesta
                            },
                            () => {
                                if (this.state.respuestaSp === "OK") {
                                    this.setState({
                                        mensajeAlerta:
                                            "Registrado correctamente",
                                        mensajeColor: "green",
                                        respError: ""
                                    });
                                    this.getCursos(this.state.acti);
                                } else {
                                    this.setState({
                                        respError: this.state.respuestaSp
                                    });
                                    swal({
                                        title: `Intento de Baja de ${a_reg.curs_deno} `,
                                        text: `Mensaje:${this.state.respuestaSp}`
                                    });
                                }
                            }
                        );
                    })
                    .catch(error => {
                        alert("ERROR interno API al actualizar BD:" + error);
                    });
            } else {
                this.setState({
                    mensajeAlerta: "No se aplico baja",
                    mensajeColor: "red"
                });
            }
        });
    };
    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        const registrosFiltrados = this.filtrarDatos();
        const respError = this.state.respError;
        //const mensajeAlerta = this.state.mensajeAlerta;
        //const mensajeColor = this.state.mensajeColor;

        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ fontSize: "22px" }}>
                            <b>Definir cursos</b>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            {this.state.login_usua_nivel === "A" ? (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={this.handleModalAlta}
                                >
                                    + Curso
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled
                                >
                                    Solo Lectura
                                </Button>
                            )}
                        </Col>
                        <Col xs={1}>
                            {/*
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                    this.setState({ showhelp: true });
                                }}
                            >
                                Ayuda
                            </Button>
                            */}
                        </Col>
                        <Col xs={1}>
                            {/*
                            {this.state.registros.length > 0 && (
                                <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={e =>
                                        exportToCSV(
                                            this.state.expTitulo,
                                            this.state.expSubtit,
                                            this.state.expSql
                                        )
                                    }
                                >
                                    <FaDownload /> Excel
                                </Button>
                            )}
                                */}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "simo_deno"
                                                    )
                                                }
                                            >
                                                Módulo
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_orden_simo"
                                                    )
                                                }
                                            >
                                                Ord Visual
                                            </Button>
                                        </th>

                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_deno"
                                                    )
                                                }
                                            >
                                                Denominación
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "tper_deno"
                                                    )
                                                }
                                            >
                                                Tip.Personal
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Perfil Usuario
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("canti")
                                                }
                                            >
                                                #Usuarios
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_alta_f"
                                                    )
                                                }
                                            >
                                                Alta
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "curs_baja_f"
                                                    )
                                                }
                                            >
                                                Baja
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.simo_color
                                                    }}
                                                >
                                                    {regis.simo_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.simo_color,
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.curs_orden_simo}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.curs_id}
                                                </td>
                                                <td>{regis.curs_deno}</td>
                                                <td>{regis.tper_deno}</td>
                                                <td>{regis.perf_deno}</td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.canti}
                                                </td>
                                                <td>{regis.curs_alta_f}</td>
                                                <td>{regis.curs_baja_f}</td>
                                                <td>
                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" && (
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={() =>
                                                                    this.handleModalEdit(
                                                                        regis
                                                                    )
                                                                }
                                                            >
                                                                <FaRegEdit />
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                onClick={() => {
                                                                    this.handleEliminar(
                                                                        regis
                                                                    );
                                                                }}
                                                            >
                                                                <FaTrashAlt />
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModalEdit}
                    onHide={this.handleModalExit}
                    dialogClassName="modal-90"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.curs_id === 0
                                ? "Alta de Curso"
                                : `Modifica Curso #${this.state.curs_id}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={1}>
                                    <FormGroup>
                                        #Id
                                        <FormControl
                                            type="text"
                                            name={"curs_id"}
                                            value={this.state.curs_id}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        Denominación
                                        <FormControl
                                            type="text"
                                            name={"curs_deno"}
                                            value={this.state.curs_deno}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} style={{ textAlign: "right" }}>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={this.handleGrabar}
                                    >
                                        Grabar
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={this.handleModalExit}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1} />
                                <Col md={3}>
                                    <FormGroup>
                                        Módulo
                                        <select
                                            className="form-control"
                                            name="curs_simo_id"
                                            value={this.state.curs_simo_id}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.curs_simo_id === 0 && ( // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                                <option
                                                    key={"0"}
                                                    value={0}
                                                    disabled
                                                >
                                                    ( Seleccione Módulo )
                                                </option>
                                            )}
                                            {this.state.modulos.map((m, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={m.simo_id}
                                                    >
                                                        {" "}
                                                        {m.simo_deno}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xs={1}>
                                    <FormGroup>
                                        #Ord visual
                                        <FormControl
                                            type="number"
                                            name={"curs_orden_simo"}
                                            value={this.state.curs_orden_simo}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={2}>
                                    <Form.Group>
                                        Tipo de Personal
                                        <select
                                            className="form-control"
                                            name="curs_tper_id"
                                            value={this.state.curs_tper_id}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.curs_tper_id === 0 && ( // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                                <option
                                                    key={"0"}
                                                    value={0}
                                                    disabled
                                                >
                                                    ( Seleccione tipo de
                                                    personal )
                                                </option>
                                            )}
                                            {this.state.personaTipo.map(mod => {
                                                return (
                                                    <option
                                                        key={mod.value}
                                                        value={mod.value}
                                                    >
                                                        {mod.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group>
                                        Perfil que aplica
                                        <Select
                                            name="curs_perf_id"
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangePerfiles}
                                            isMulti
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            options={this.state.perfiles}
                                        />
                                        {/*
                                        <select
                                            className="form-control"
                                            name="curs_perf_id"
                                            value={this.state.curs_perf_id}
                                            style={{
                                                backgroundColor: "#e6fff2"
                                            }}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.curs_perf_id === 0 && ( // Cuando esta vacio ponemos un reg de 'Seleccion' para que haga el change
                                                <option
                                                    key={"0"}
                                                    value={0}
                                                    disabled
                                                >
                                                    ( Seleccione perfil )
                                                </option>
                                            )}
                                            {this.state.perfiles.map(per => {
                                                return (
                                                    <option
                                                        key={per.perf_id}
                                                        value={per.perf_id}
                                                    >
                                                        {per.perf_deno}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        */}
                                    </Form.Group>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            {respError.length > 3 ? (
                                <Row>
                                    <Col>
                                        <Alert
                                            key="1"
                                            variant="danger"
                                            show={
                                                respError.length > 3
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {respError}
                                        </Alert>
                                    </Col>
                                </Row>
                            ) : (
                                <br />
                            )}

                            <Row>
                                <Col>
                                    <Table
                                        bordered
                                        hover
                                        size="sm"
                                        id="data_table"
                                    >
                                        <thead className="Grilla-header">
                                            <tr key={10000}>
                                                <th
                                                    style={{
                                                        width: "7%",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Orden
                                                </th>
                                                <th
                                                    style={{
                                                        width: "80%"
                                                    }}
                                                >
                                                    Descripción del Tópico
                                                </th>

                                                <th
                                                    style={{
                                                        width: "13%"
                                                    }}
                                                >
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.topicos.map(
                                                (item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                <FormControl
                                                                    type="text"
                                                                    name={
                                                                        "ctop_orden"
                                                                    }
                                                                    value={
                                                                        item.ctop_orden
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#e6fff2"
                                                                    }}
                                                                    onChange={e =>
                                                                        this.handleChangeItem(
                                                                            e,
                                                                            i
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <FormControl
                                                                        type="text"
                                                                        name={
                                                                            "ctop_deno"
                                                                        }
                                                                        placeholder="Escribe la descripción del tópico"
                                                                        value={
                                                                            item.ctop_deno
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#e6fff2"
                                                                        }}
                                                                        onChange={e =>
                                                                            this.handleChangeItem(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex"
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "5%",
                                                                            marginTop:
                                                                                "10px"
                                                                        }}
                                                                    >
                                                                        Video:
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: "45%"
                                                                        }}
                                                                    >
                                                                        <FormControl
                                                                            type="text"
                                                                            name={
                                                                                "ctop_video"
                                                                            }
                                                                            value={
                                                                                item.ctop_video
                                                                            }
                                                                            onChange={e =>
                                                                                this.handleChangeItem(
                                                                                    e,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: "10%",
                                                                            marginTop:
                                                                                "10px",
                                                                            textAlign:
                                                                                "center"
                                                                        }}
                                                                    >
                                                                        Imagen:
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: "40%"
                                                                        }}
                                                                    >
                                                                        <FormControl
                                                                            type="text"
                                                                            name={
                                                                                "ctop_img"
                                                                            }
                                                                            value={
                                                                                item.ctop_img
                                                                            }
                                                                            onChange={e =>
                                                                                this.handleChangeItem(
                                                                                    e,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "5%"
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={e =>
                                                                        this.borraItem(
                                                                            item.ctop_id,
                                                                            `${item.ctop_orden}-${item.ctop_deno}`
                                                                        )
                                                                    }
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                                <Button
                                                                    variant="primary"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.insertItem(
                                                                            i
                                                                        );
                                                                    }}
                                                                >
                                                                    <CgInsertAfterR />
                                                                </Button>
                                                                {` #${item.ctop_id} `}

                                                                <Button
                                                                    variant={
                                                                        item.ctop_video !==
                                                                        null
                                                                            ? "success"
                                                                            : "danger"
                                                                    }
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.handleModalPreview(
                                                                            item.ctop_video,
                                                                            item.ctop_img
                                                                        );
                                                                    }}
                                                                >
                                                                    <FaPlay />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showModalPreview}
                    onHide={() => this.setState({ showModalPreview: false })}
                    dialogClassName="modal-50"
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#b3d9ff" }}
                    >
                        <Modal.Title>Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="home" title="Video">
                                <div className="player-wrapper">
                                    <ReactPlayer
                                        height={"360px"}
                                        width={"585px"}
                                        playing={true}
                                        controls
                                        url={this.state.video_topico_prev}
                                        // disable right click
                                        onContextMenu={e => e.preventDefault()}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="tab3" title={"Infografía"}>
                                <div className="infografia">
                                    <Image
                                        src={this.state.imagen_topico_prev}
                                        fluid
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default DefCursos;
