//import logo from './logo.svg';
import "./App.css";

import React from "react";
import "./assets/styles/index.scss"; // CSS IMPORTS
import {
    Redirect,
    Switch,
    Route,
    BrowserRouter as Router
} from "react-router-dom";

import Header from "./components/Header";

//import Home from './pages/home'
import Videos_Tutorial from "./pages/videos_tutorial";
import Img_Tutorial from "./pages/img_tutorial";
import Cursos from "./pages/cursos";
import AsignaCursos from "./pages/asigna_cursos";
import AsignaMasivo from "./pages/asigna_masivo";
import CuadroVideo from "./pages/cuadro_videos";
import UsuaTablasPage from "./pages/usua_tablas";
import TablasCursosPage from "./pages/tablas_cursos";
import DatosUsuario from "./components/UsuarioLogueado";
import Login from "./components/Login";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login_usua_id: sessionStorage.getItem("USUARIO")
        };
    }

    componentDidMount() {
        if (sessionStorage.getItem("USUARIO") === 0) {
            sessionStorage.setItem("NOMBRE_USUARIO", "Usuario Log");
        }
    }

    render() {
        return (
            <div>
                <Router>
                    {/* HEADER */}
                    <Header a_usu_login={"cocota"} />
                    {/* BODY DINAMIC CONTENT */}
                    <Switch>
                        <Route
                            exact
                            path="/videos_tutorial"
                            component={Videos_Tutorial}
                        />
                        <Route
                            exact
                            path="/img_tutorial"
                            component={Img_Tutorial}
                        />
                        <Route exact path="/cursos" component={Cursos} />
                        <Route
                            exact
                            path="/UsuarioLogueado"
                            component={DatosUsuario}
                        />
                        <Route
                            exact
                            path="/asigna_cursos"
                            component={AsignaCursos}
                        />
                        <Route
                            exact
                            path="/asigna_masivo"
                            component={AsignaMasivo}
                        />
                        <Route
                            exacts
                            path="/cuadro_videos"
                            component={CuadroVideo}
                        />
                        <Route
                            exacts
                            path="/usua_tablas"
                            component={UsuaTablasPage}
                        />
                        <Route
                            exacts
                            path="/tablas_cursos"
                            component={TablasCursosPage}
                        />
                        <Route path="/login" component={Login} />
                        {/*          <Route path="/datos-usuario" component={DatosUsuario} />*/}
                        {/*          <Route path="*" component={Home} /> */}
                    </Switch>

                    {Number(this.state.login_usua_id) === 0 && (
                        <Redirect to={"/login"} />
                    )}

                    {/* FOOTER */}
                </Router>
            </div>
        );
    }
}

export default App;
