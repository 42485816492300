import React from "react";
import { URL_DB } from "./../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl
} from "react-bootstrap";
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
//import AyudaAdmi from '../../components/Ayuda-admi';
import Notifications from "../../components/Notifications";
import ordenarGrilla from "./../../utils/functions/ordenar-grilla";
import "../../pages/stylePages.css";

import { BsFillRecordFill } from "react-icons/bs";
import swal from "sweetalert";
// import ShowAccesoMenues from "../ShowAccesoMenues";

// FaPlus
class ModiGlobal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sectores: [],
            perfiles: [],
            personaTipo: [],
            sect_id: 0,
            buscarGrillaValue: "",
            showModalEdit: false,
            showModalPwd: false,
            showHelpMenues: false,
            login_user_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),

            usua_id: "",
            usua_usuario: "",
            usua_nombre: "",
            usua_apellido: "",
            usua_docum: "",
            usua_celular: "",
            usua_nivel: "U",
            usua_sect_id: 0,
            usua_perf_id: 0,
            usua_tper_id: 0,

            help_usua_id: 0,
            help_rol: "",
            showhelp: false,
            filterGrilla: "",
            fetchingregistros: false,
            fetchRolesLista: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };

        this.ordenarGrilla = this.ordenarGrilla.bind(this);
    }

    async getUsuarios() {
        const sql = `${URL_DB}SEL_USUARIOS(null)`;
        const response = await axios.get(sql);
        try {
            this.setState({ registros: response.data[0] });
        } catch (error) {
            console.log(error);
        }
    }

    async getSectores() {
        const sql = `${URL_DB}SEL_USUA_SEDE_SECTOR_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    sectores: response.data[0].filter(f => f.sect_id >= 0),
                    sect_id: response.data[0][0].sect_id
                });
            })
            .catch(error => console.log(error));
    }

    async getPerfiles() {
        const sql = `${URL_DB}SEL_USUA_PERFIL(null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    perfiles: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }
    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }
    async getInicio() {
        try {
            await Promise.all([
                this.getSectores(),
                this.getPerfiles(),
                this.getUsuarios(),
                this.getPersonaTipo()
            ]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.usua_usuario) ||
                regex.test(filtro.usua_nombre) ||
                regex.test(filtro.usua_apellido) ||
                regex.test(filtro.usua_docum) ||
                regex.test(filtro.tper_deno) ||
                regex.test(filtro.perf_deno) ||
                regex.test(filtro.sect_deno) ||
                regex.test(filtro.sede_deno)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChangeItem(event, a_usua_id) {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let registros = this.state.registros;
        // si se aplica filtro pierdo la fila
        const fila = registros.findIndex(fila => fila.usua_id === a_usua_id);

        if (name === "usua_docum") {
            registros[fila].usua_docum = value;
        }
        if (name === "usua_tper_id") {
            registros[fila].usua_tper_id = value;
        }
        if (name === "usua_perf_id") {
            registros[fila].usua_perf_id = value;
        }
        if (name === "usua_sect_id") {
            registros[fila].usua_sect_id = value;
        }
        registros[fila].aux = "X";

        this.setState({ registros });
    }

    /*==========================================================================
        Actualizacion : Modificacion usuario y sus accesos
    *==========================================================================*/

    handleGrabar = event => {
        let cambios = this.state.registros
            .filter(f => {
                return f.aux === "X";
            })
            .map(e => {
                let obj = {};
                obj.usua_id = e.usua_id;
                obj.usua_docum = e.usua_docum;
                obj.usua_tper_id = e.usua_tper_id;
                obj.usua_perf_id = e.usua_perf_id;
                obj.usua_sect_id = e.usua_sect_id;
                return obj;
            });

        if (cambios.length === 0) {
            swal({
                title: `Error`,
                text: `No hay registros modificados`,
                icon: "error"
            });
        } else {
            const sql = `${URL_DB}M_USUARIO_ATRIB_GLOBAL(${
                this.state.login_user_id
            },'${String(JSON.stringify(cambios))}')`;
            console.log(sql);
            axios
                .get(sql)
                .then(response => {
                    this.setState({
                        respuestaSp: response.data[0]
                    });
                    var obj = this.state.respuestaSp[0];
                    this.setState({
                        respError: obj.respuesta
                    });

                    if (this.state.respError === "OK") {
                        this.setState({
                            mensajeAlerta: "Registrado correctamente",
                            mensajeColor: "green",
                            showModalEdit: false
                        });
                        this.getUsuarios();
                    }
                })
                .catch(error => {
                    alert("ERROR interno API al actualizar BD:" + error);
                });
        }
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/
    render() {
        // const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}
                <Container fluid="true">
                    <Row>
                        <Col xs={6} style={{ fontSize: "22px" }}>
                            <b>
                                Modificación Global de datos secundarios de
                                Usuarios
                            </b>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={1}>
                            {this.state.login_usua_nivel === "A" ? (
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={this.handleGrabar}
                                >
                                    Grabar
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled
                                >
                                    Solo Lectura
                                </Button>
                            )}
                        </Col>
                        <Col xs={1}>
                            {this.state.login_usua_nivel === "A" && (
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => this.getUsuarios()}
                                >
                                    Reset
                                </Button>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_usuario"
                                                    )
                                                }
                                            >
                                                Usuario
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_apellido"
                                                    )
                                                }
                                            >
                                                Apellido y Nombre
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_docum"
                                                    )
                                                }
                                            >
                                                Documento
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "tper_deno"
                                                    )
                                                }
                                            >
                                                T.Personal
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Perfil
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sect_deno"
                                                    )
                                                }
                                            >
                                                Sector
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sede_deno"
                                                    )
                                                }
                                            >
                                                Sede
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_nivel"
                                                    )
                                                }
                                            >
                                                Acceso
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_modi_f"
                                                    )
                                                }
                                            >
                                                Actualizac
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchingregistros &&
                                        "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    backgroundColor: regis.color
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_id}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.usua_usuario}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {`${regis.usua_apellido}, ${regis.usua_nombre}`}
                                                </td>

                                                <td>
                                                    <FormControl
                                                        type="text"
                                                        name={"usua_docum"}
                                                        value={regis.usua_docum}
                                                        style={{
                                                            backgroundColor:
                                                                "#e6fff2"
                                                        }}
                                                        onChange={e =>
                                                            this.handleChangeItem(
                                                                e,
                                                                regis.usua_id
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control"
                                                        name="usua_tper_id"
                                                        value={
                                                            regis.usua_tper_id
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "#e6fff2"
                                                        }}
                                                        onChange={e =>
                                                            this.handleChangeItem(
                                                                e,
                                                                regis.usua_id
                                                            )
                                                        }
                                                    >
                                                        {this.state.personaTipo.map(
                                                            mod => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            mod.value
                                                                        }
                                                                        value={
                                                                            mod.value
                                                                        }
                                                                    >
                                                                        {
                                                                            mod.label
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control"
                                                        name="usua_perf_id"
                                                        value={
                                                            regis.usua_perf_id
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "#e6fff2"
                                                        }}
                                                        onChange={e =>
                                                            this.handleChangeItem(
                                                                e,
                                                                regis.usua_id
                                                            )
                                                        }
                                                    >
                                                        {this.state.perfiles.map(
                                                            per => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            per.perf_id
                                                                        }
                                                                        value={
                                                                            per.perf_id
                                                                        }
                                                                    >
                                                                        {
                                                                            per.perf_deno
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control"
                                                        name="usua_sect_id"
                                                        value={
                                                            regis.usua_sect_id
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "#e6fff2"
                                                        }}
                                                        onChange={e =>
                                                            this.handleChangeItem(
                                                                e,
                                                                regis.usua_id
                                                            )
                                                        }
                                                    >
                                                        {this.state.sectores.map(
                                                            sec => {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            sec.sect_id
                                                                        }
                                                                        value={
                                                                            sec.sect_id
                                                                        }
                                                                    >
                                                                        {
                                                                            sec.deno
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.sede_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_nivel}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_modi_f === null
                                                        ? regis.usua_alta_f
                                                        : regis.usua_modi_f}
                                                </td>
                                                <td>
                                                    {regis.aux === "X" && (
                                                        <BsFillRecordFill
                                                            color={"red"}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot className="Grilla-header">
                                    <tr key={100001}>
                                        <td />
                                        <td
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >{`${registrosFiltrados.length} Reg`}</td>
                                        <td colSpan={8} />
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ModiGlobal;
