import React from "react";
import { URL_DB } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl
} from "react-bootstrap";
// import exportToCSV from "../../utils/functions/export-excel";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import "../../pages/stylePages.css";

class TablasLog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sedes: [],
            login_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            simo_id: 0,
            simo_deno: "",
            simo_sede_id: 0,
            filterGrilla: "",
            showModalEdit: false,
            fetchRegistros: false,
            expSql: "",
            expTitulo: "Reporte de Modulos",
            expSubtit: "",
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }

    async getModulos() {
        const sql = `${URL_DB}SEL_USUARIOS_LOG('CURSOS',null,null,null,null,null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getModulos()]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.ulog_fecha_hora) ||
                regex.test(filtro.usu_apenom) ||
                regex.test(filtro.ulog_clave) ||
                regex.test(filtro.ulog_param) ||
                regex.test(filtro.ulog_acciones)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        const registrosFiltrados = this.filtrarDatos();
        // const respError = this.state.respError;
        //const mensajeAlerta = this.state.mensajeAlerta;
        //const mensajeColor = this.state.mensajeColor;

        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Col xs={6} style={{ fontSize: "22px" }}>
                            <b>Log de Tablas de definición de cursos</b>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            {this.state.login_usua_nivel === "A" ? (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={this.handleModalAlta}
                                >
                                    + Módulo
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled
                                >
                                    Solo Lectura
                                </Button>
                            )}
                        </Col>
                        <Col xs={1}></Col>
                        <Col xs={1}></Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ulog_fecha_hora"
                                                    )
                                                }
                                            >
                                                Fecha hora
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usu_apenom"
                                                    )
                                                }
                                            >
                                                Nombre usuario
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ulog_param"
                                                    )
                                                }
                                            >
                                                #Referencia
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "ulog_acciones"
                                                    )
                                                }
                                            >
                                                Acciones
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    backgroundColor:
                                                        regis.color_fila
                                                }}
                                            >
                                                <td>{regis.ulog_fecha_hora}</td>
                                                <td>{regis.usu_apenom}</td>
                                                <td>
                                                    {`${regis.ulog_clave} #${regis.ulog_param}`}
                                                </td>
                                                <td>{regis.ulog_acciones}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default TablasLog;
