import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import { Table, Row, Col, Container, Button, Form } from "react-bootstrap";
// import swal from "sweetalert";
import AutosuggestsComponent from "../../components/Autosuggest";
import { FaTimes, FaCheck, FaAngleDown } from "react-icons/fa";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import DetaCursoVistas from "../../../src/components/DetaCursoVistas";
import "../stylePages.css";

class AsignaCursos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tper_id: 2,
            explica: "T",
            user_curso: 0,
            registros: [],
            topicos: [],
            personaTipo: [],
            // recorrido: [],
            cur_fila: 0,
            autosuggestValue: "",

            filterGrilla: "",
            login_usua_id: sessionStorage.getItem("USUARIO"),

            fetchRegistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
    }

    async getGrilla(a_tper_id, a_user_curso) {
        this.setState({ tper_id: a_tper_id });

        const sql = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${a_tper_id},${a_user_curso},'C')`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                    // recorrido: response.data[0]
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });

        const sql2 = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${a_tper_id},${a_user_curso},'D')`;
        axios
            .get(sql2)
            .then(response => {
                this.setState({
                    topicos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([
                this.getPersonaTipo() /*,
                this.getGrilla(this.state.tper_id) */
            ]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
    }

    /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/

    filtrarDatos = () => {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.uso_deno) || regex.test(filtro.tema_deno)
        );
    };

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChangeSugg_usuario_codi = suggestion => {
        this.setState(
            {
                user_curso: suggestion.codigo
            },
            () => {
                this.getGrilla(this.state.tper_id, this.state.user_curso);
            }
        );
    };

    handleChangeAsigna = a_curs_id => {
        let registros = this.state.registros;

        for (let i = 0; i < registros.length; i++) {
            if (registros[i].curs_id === a_curs_id) {
                registros[i].si_no = registros[i].si_no === "S" ? "N" : "S";
            }
        }

        this.setState({
            registros
        });
    };

    cargoDetalle = a_curs_id => {
        let registros = this.state.registros;

        for (let i = 0; i < registros.length; i++) {
            if (registros[i].curs_id === a_curs_id) {
                registros[i].aux = registros[i].aux === "S" ? "N" : "S";
            }
        }

        this.setState({
            registros
        });
    };

    /*==========================================================================
       Actualizacion : asiga cursos de todas  las filas cuando graba
    *==========================================================================*/
    handleGrabar = event => {
        event.preventDefault();

        var cursos_json = this.state.registros.map(item => {
            var obj = {};
            obj.curs_id = item.curs_id;
            obj.si_no = item.si_no;
            return obj;
        });

        const sql = `${URL_DB}AM_ASIGNA_CURSO(${this.state.login_usua_id},${
            this.state.user_curso
        },'${String(JSON.stringify(cursos_json))}')`;
        axios
            .get(sql)
            .then(response => {
                this.setState(
                    {
                        respuestaSp: response.data[0][0].respuesta
                    },
                    () => {
                        if (this.state.respuestaSp === "OK") {
                            this.setState({
                                mensajeAlerta: "Registrado correctamente",
                                mensajeColor: "green",
                                respError: ""
                            });
                        } else {
                            this.setState({
                                respError: this.state.respuestaSp
                            });
                        }
                    }
                );
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error);
            });
    };

    /*==========================================================================
   RENDER
  *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Asigna Cursos por persona</b>
                            </h2>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <b>Seleccione personal</b>
                                <select
                                    className="form-control"
                                    name="simo_id"
                                    value={this.state.tper_id}
                                    style={{ fontWeight: "bold" }}
                                    onChange={e => {
                                        this.getGrilla(
                                            e.target.value,
                                            this.state.user_curso
                                        );
                                    }}
                                >
                                    {this.state.personaTipo.map(mod => {
                                        return (
                                            <option
                                                key={mod.value}
                                                value={mod.value}
                                            >
                                                {mod.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Form.Group>
                        </Col>

                        <Col xs={5}>
                            <Form.Group>
                                <b>Usuario a asignar</b>
                                <AutosuggestsComponent
                                    tabla={"usuario"}
                                    placeholder={"Digite Apellido del usuario"}
                                    denoValue={this.state.autosuggestValue}
                                    onSubmitFunction={
                                        this.handleChangeSugg_usuario_codi
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={1}>
                            <Button
                                variant="success"
                                size="sm"
                                onClick={this.handleGrabar}
                            >
                                Grabar
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table bordered hover size="sm" id="data_table">
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>#Id</th>
                                        <th>Curso</th>
                                        <th>Módulo</th>
                                        <th>Perfil Usuario</th>
                                        <th>Tópicos</th>
                                        <th>Asignar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}

                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    backgroundColor: regis.color
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        width: "5%",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.curs_id}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "20%"
                                                    }}
                                                >
                                                    {regis.curs_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "15%",
                                                        backgroundColor:
                                                            regis.simo_color
                                                    }}
                                                >
                                                    {regis.simo_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "10%"
                                                    }}
                                                >
                                                    {regis.perf_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "35%"
                                                    }}
                                                >
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: "250px"
                                                            }}
                                                        >
                                                            <div className="progress">
                                                                <div
                                                                    className="progress-bar progress-bar-striped bg-success"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width:
                                                                            regis.porcen_vista +
                                                                            "%"
                                                                    }}
                                                                    aria-valuenow={
                                                                        regis.porcen_vista
                                                                    }
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                    {String(
                                                                        regis.porcen_vista
                                                                    ) + "%"}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "75px"
                                                            }}
                                                        >
                                                            {
                                                                regis.ult_fecha_vista
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "30px"
                                                            }}
                                                        >
                                                            {regis.porcen_vista >
                                                                0 && (
                                                                <Button
                                                                    variant="info"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        this.cargoDetalle(
                                                                            regis.curs_id
                                                                        )
                                                                    }
                                                                >
                                                                    <FaAngleDown />
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {regis.aux === "S" && (
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <DetaCursoVistas
                                                                    regDetalle={this.state.topicos.filter(
                                                                        e => {
                                                                            return (
                                                                                e.ctop_curs_id ===
                                                                                regis.curs_id
                                                                            );
                                                                        }
                                                                    )}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </td>
                                                <td style={{ width: "10%" }}>
                                                    <div
                                                        onClick={() =>
                                                            this.handleChangeAsigna(
                                                                regis.curs_id
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                regis.si_no ===
                                                                "S"
                                                                    ? "#1A73E8"
                                                                    : "white",
                                                            color:
                                                                regis.si_no ===
                                                                "S"
                                                                    ? "white"
                                                                    : "black",
                                                            textAlign: "center",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {regis.si_no ===
                                                            "S" && <FaCheck />}
                                                        {regis.si_no ===
                                                            "N" && <FaTimes />}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                {registrosFiltrados.length > 0 && (
                                    <tfoot className="Grilla-header">
                                        <tr>
                                            <td
                                                style={{ textAlign: "center" }}
                                            >{`${registrosFiltrados.length} Reg`}</td>
                                            <td>
                                                {`#user${this.state.user_curso} `}
                                            </td>
                                            <td colSpan={4} />
                                        </tr>
                                    </tfoot>
                                )}
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AsignaCursos;
