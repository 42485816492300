import React from "react";
import { URL_DB } from "./../../constants";
import axios from "axios";
//import ReactPlayer from 'react-player';
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Modal,
    Form,
    FormGroup,
    FormControl,
    Card,
    Alert
} from "react-bootstrap";
//import ExpGrillaExcelPDF from '../../components/ExpGrillaExcelPDF';
//import AyudaAdmi from '../../components/Ayuda-admi';
import Notifications from "../../components/Notifications";
import ordenarGrilla from "./../../utils/functions/ordenar-grilla";
import "../../pages/stylePages.css";
import { FaUserPlus, FaUserEdit, FaTrashAlt } from "react-icons/fa";
import { BsFillKeyFill } from "react-icons/bs";
import swal from "sweetalert";
// import ShowAccesoMenues from "../ShowAccesoMenues";

// FaPlus
class Usuarios extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sectores: [],
            perfiles: [],
            personaTipo: [],
            sect_id: 0,
            buscarGrillaValue: "",
            showModalEdit: false,
            showModalPwd: false,
            showHelpMenues: false,
            login_user_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),

            usua_id: "",
            usua_usuario: "",
            usua_nombre: "",
            usua_apellido: "",
            usua_docum: "",
            usua_celular: "",
            usua_nivel: "U",
            usua_sect_id: 0,
            usua_perf_id: 0,
            usua_tper_id: 0,

            help_usua_id: 0,
            help_rol: "",
            showhelp: false,
            filterGrilla: "",
            fetchingregistros: false,
            fetchRolesLista: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };

        this.ordenarGrilla = this.ordenarGrilla.bind(this);
    }

    async getUsuarios() {
        const sql = `${URL_DB}SEL_USUARIOS(null)`;
        const response = await axios.get(sql);
        try {
            this.setState({ registros: response.data[0] });
        } catch (error) {
            console.log(error);
        }
    }
    async getSectores() {
        const sql = `${URL_DB}SEL_USUA_SEDE_SECTOR_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    sectores: response.data[0].filter(f => f.sect_id >= 0),
                    sect_id: response.data[0][0].sect_id
                });
            })
            .catch(error => console.log(error));
    }

    async getPerfiles() {
        const sql = `${URL_DB}SEL_USUA_PERFIL(null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    perfiles: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }
    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }
    async getInicio() {
        try {
            await Promise.all([
                this.getSectores(),
                this.getPerfiles(),
                this.getUsuarios(),
                this.getPersonaTipo()
            ]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.usua_usuario) ||
                regex.test(filtro.usua_nombre) ||
                regex.test(filtro.usua_apellido) ||
                regex.test(filtro.usua_docum) ||
                regex.test(filtro.tper_deno) ||
                regex.test(filtro.perf_deno) ||
                regex.test(filtro.sect_deno) ||
                regex.test(filtro.sede_deno)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    /*==========================================================================
   Abro modal para modificar : Cargo valores y combos
  *==========================================================================*/

    handleModalEdit(regis) {
        this.setState({
            showModalEdit: true,
            usua_id: regis.usua_id,
            usua_usuario: regis.usua_usuario,
            usua_nombre: regis.usua_nombre,
            usua_apellido: regis.usua_apellido,
            usua_docum: regis.usua_docum,
            usua_celular: regis.usua_celular,
            usua_nivel: regis.usua_nivel,
            usua_sect_id: regis.usua_sect_id,
            usua_perf_id: regis.usua_perf_id,
            usua_tper_id: regis.usua_tper_id,

            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    }

    handleModalPwd(regis) {
        this.setState({
            showModalPwd: true,
            usua_id: regis.usua_id,
            usua_usuario: regis.usua_usuario,
            usua_nombre: regis.usua_nombre,
            usua_apellido: regis.usua_apellido,
            usua_pwd: "",

            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    }

    handleModalAlta = () => {
        this.setState({
            showModalEdit: true,
            usua_id: 0,
            usua_usuario: "",
            usua_nombre: "",
            usua_apellido: "",
            usua_docum: "",
            usua_sect_id: 0,
            usua_perf_id: 1,
            usua_tper_id: 2,
            usua_nivel: "U",
            usua_pwd: "",

            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    };

    /*    handleChangeRol = (event, ind) => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        var rolesLista = this.state.rolesLista;
        rolesLista[ind].lee_mod_no = value;
        this.setState({ rolesLista });
    };
*/
    handleChange = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleModalExit = () => {
        this.setState({ showModalEdit: false });
        this.setState({
            mensajeAlerta: "No se aplicaron cambios",
            mensajeColor: "red"
        });
    };

    handleModalPwdExit = () => {
        this.setState({ showModalPwd: false });
        this.setState({
            mensajeAlerta: "No se aplicaron cambios",
            mensajeColor: "red"
        });
    };

    showHelpMenues = (p_usua_id, p_rol) => {
        this.setState({
            showHelpMenues: true,
            help_usua_id: p_usua_id,
            help_rol: p_rol
        });
    };

    /*==========================================================================
        Actualizacion : Modificacion usuario y sus accesos
    *==========================================================================*/

    handleGrabarUsuario = event => {
        const sql = `${URL_DB}AM_USUARIO(${this.state.login_user_id},${
            this.state.usua_id
        },'${encodeURIComponent(
            this.state.usua_usuario
        )}','${encodeURIComponent(
            this.state.usua_nombre
        )}','${encodeURIComponent(
            this.state.usua_apellido
        )}','${encodeURIComponent(
            this.state.usua_docum
        )}','${encodeURIComponent(this.state.usua_celular)}','${
            this.state.usua_nivel
        }',${this.state.usua_sect_id},${this.state.usua_tper_id},${
            this.state.usua_perf_id
        },'${encodeURIComponent(this.state.usua_pwd)}')`;

        console.log(sql);
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalEdit: false
                    });
                    this.getUsuarios();
                }
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error);
            });
    };

    handleModalBaja = reg => {
        swal({
            title: `Eliminar Usuario ${reg.usua_nombre} ${reg.usua_apellido}    (#ID ${reg.usua_id})`,
            text: "Al grabar se elimina definitivamente, confirma ?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(respuesta => {
            if (respuesta === true) {
                this.BorrarUsuario(reg);
            }
        });
    };

    BorrarUsuario = reg => {
        const sql = `${URL_DB}B_USUARIO(${this.state.login_user_id},${reg.usua_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalEdit: false
                    });
                    this.getUsuarios();
                }
            })
            .catch(error => {
                alert(
                    "ERROR interno API al actualizar BD:" +
                        error +
                        " SQL:" +
                        sql
                );
            });
    };

    cambiarPaswordUsuario = event => {
        const sql = `${URL_DB}AM_USUARIO_RESET_PWD(${
            this.state.login_user_id
        },${this.state.usua_id},'${encodeURIComponent(this.state.usua_pwd)}')`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalPwd: false
                    });
                    this.getUsuarios();
                }
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error);
            });
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/
    render() {
        const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}
                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ fontSize: "22px" }}>
                            <b>Usuarios</b>
                        </Col>
                        <Col xs={4}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            {this.state.login_usua_nivel === "A" ? (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    type="submit"
                                    onClick={this.handleModalAlta}
                                >
                                    <FaUserPlus /> Usuario
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    disabled
                                >
                                    Solo Lectura
                                </Button>
                            )}
                        </Col>
                        <Col xs={2}>
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                    this.setState({ showhelp: true });
                                }}
                            >
                                Ayuda
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_usuario"
                                                    )
                                                }
                                            >
                                                Usuario
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_apellido"
                                                    )
                                                }
                                            >
                                                Apellido
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_nombre"
                                                    )
                                                }
                                            >
                                                Nombre
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_docum"
                                                    )
                                                }
                                            >
                                                Documento
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "tper_deno"
                                                    )
                                                }
                                            >
                                                T.Personal
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "perf_deno"
                                                    )
                                                }
                                            >
                                                Perfil
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_alta_f"
                                                    )
                                                }
                                            >
                                                Alta
                                            </Button>
                                        </th>

                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sect_deno"
                                                    )
                                                }
                                            >
                                                Sector
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sede_deno"
                                                    )
                                                }
                                            >
                                                Sede
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "usua_nivel"
                                                    )
                                                }
                                            >
                                                Acceso
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchingregistros &&
                                        "Cargando..."}
                                    {registrosFiltrados.map(regis => {
                                        return (
                                            <tr
                                                key={regis.usua_id}
                                                style={{
                                                    backgroundColor: regis.color
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_id}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.usua_usuario}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.usua_apellido}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.usua_nombre}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.usua_docum}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.tper_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.perf_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_alta_f}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.sect_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    {regis.sede_deno}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.usua_nivel}
                                                </td>
                                                <td>
                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" &&
                                                        regis.usua_baja_f ===
                                                            null && (
                                                            <>
                                                                <Button
                                                                    variant="primary"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        this.handleModalEdit(
                                                                            regis
                                                                        )
                                                                    }
                                                                >
                                                                    <FaUserEdit />{" "}
                                                                </Button>
                                                            </>
                                                        )}
                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" &&
                                                        regis.usua_baja_f ===
                                                            null && (
                                                            <>
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        this.handleModalBaja(
                                                                            regis
                                                                        )
                                                                    }
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>

                                                                <Button
                                                                    variant="outline-warning"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        this.handleModalPwd(
                                                                            regis
                                                                        )
                                                                    }
                                                                >
                                                                    <BsFillKeyFill />
                                                                </Button>
                                                            </>
                                                        )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot className="Grilla-header">
                                    <tr key={100001}>
                                        <td />
                                        <td
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >{`${registrosFiltrados.length} Reg`}</td>
                                        <td colSpan={9} />
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    show={this.state.showModalEdit}
                    onHide={this.handleModalExit}
                    dialogClassName="modal-60"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.usua_id === 0
                                ? "Crear un Usuario "
                                : `Editar Usuario ID# ${this.state.usua_id}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                <b>Datos personales</b>
                                            </Card.Title>
                                            <FormGroup controlId="nombre">
                                                Usuario (mail activo)
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_usuario
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_usuario:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="nombre">
                                                Nombre
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_nombre
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_nombre:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="apellido">
                                                Apellido
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_apellido
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_apellido:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="usua_docum">
                                                Nro docum
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_docum
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_docum:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="usua_celular">
                                                Celular
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_celular
                                                    }
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_celular:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                            <br />
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={6}>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                Seleccione Tipo Personal
                                                <select
                                                    className="form-control"
                                                    name="usua_tper_id"
                                                    value={
                                                        this.state.usua_tper_id
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            usua_tper_id:
                                                                e.target.value
                                                        });
                                                    }}
                                                >
                                                    {this.state.personaTipo.map(
                                                        mod => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        mod.value
                                                                    }
                                                                    value={
                                                                        mod.value
                                                                    }
                                                                >
                                                                    {mod.label}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                Seleccione sector
                                                <select
                                                    className="form-control"
                                                    name="usua_sect_id"
                                                    value={
                                                        this.state.usua_sect_id
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            usua_sect_id:
                                                                e.target.value
                                                        });
                                                    }}
                                                >
                                                    {this.state.sectores.map(
                                                        sec => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        sec.sect_id
                                                                    }
                                                                    value={
                                                                        sec.sect_id
                                                                    }
                                                                >
                                                                    {sec.deno}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                Seleccione Perfil en el Sector
                                                <select
                                                    className="form-control"
                                                    name="usua_perf_id"
                                                    value={
                                                        this.state.usua_perf_id
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            usua_perf_id:
                                                                e.target.value
                                                        });
                                                    }}
                                                >
                                                    {this.state.perfiles.map(
                                                        per => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        per.perf_id
                                                                    }
                                                                    value={
                                                                        per.perf_id
                                                                    }
                                                                >
                                                                    {
                                                                        per.perf_deno
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                Perfil en este sistema
                                                <select
                                                    className="form-control"
                                                    name="usua_nivel"
                                                    value={
                                                        this.state.usua_nivel
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            "#e6fff2"
                                                    }}
                                                    onChange={e => {
                                                        this.setState({
                                                            usua_nivel:
                                                                e.target.value
                                                        });
                                                    }}
                                                >
                                                    <option key={1} value={"U"}>
                                                        Usuario
                                                    </option>
                                                    <option key={2} value={"S"}>
                                                        Superv
                                                    </option>
                                                    <option key={3} value={"A"}>
                                                        Admin
                                                    </option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            {this.state.usua_id === 0 && (
                                                <FormGroup controlId="usua_pwd">
                                                    Contraseña de inicio
                                                    <FormControl
                                                        type="text"
                                                        value={
                                                            this.state.usua_pwd
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "#e6fff2"
                                                        }}
                                                        onChange={e =>
                                                            this.setState({
                                                                usua_pwd:
                                                                    e.target
                                                                        .value
                                                            })
                                                        }
                                                    />
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={
                                                    this.handleGrabarUsuario
                                                }
                                            >
                                                Grabar
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={this.handleModalExit}
                                            >
                                                Cancelar
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Alert
                                                key="1"
                                                variant="danger"
                                                show={
                                                    respError.length > 3
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {respError}
                                            </Alert>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showModalPwd}
                    onHide={this.handleModalPwdExit}
                    dialogClassName="modal-60"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`Reset de Password Usuario ID# ${this.state.usua_id}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                <b>Datos personales</b>
                                            </Card.Title>
                                            <FormGroup controlId="nombre">
                                                Usuario (mail activo)
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_usuario
                                                    }
                                                    readOnly
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="nombre">
                                                Nombre
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_nombre
                                                    }
                                                    readOnly
                                                />
                                            </FormGroup>
                                            <FormGroup controlId="apellido">
                                                Apellido
                                                <FormControl
                                                    type="text"
                                                    value={
                                                        this.state.usua_apellido
                                                    }
                                                    readOnly
                                                />
                                            </FormGroup>
                                            <br />
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={6}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Row>
                                        <Col>
                                            <FormGroup controlId="usua_pwd">
                                                Contraseña
                                                <FormControl
                                                    type="text"
                                                    value={this.state.usua_pwd}
                                                    onChange={e =>
                                                        this.setState({
                                                            usua_pwd:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={
                                                    this.handleModalPwdExit
                                                }
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={
                                                    this.cambiarPaswordUsuario
                                                }
                                            >
                                                Reset Password
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Alert
                                                key="1"
                                                variant="danger"
                                                show={
                                                    respError.length > 3
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {respError}
                                            </Alert>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showHelpMenues}
                    onHide={() => {
                        this.setState({ showHelpMenues: false });
                    }}
                    dialogClassName="modal-40"
                >
                    <Modal.Header closeButton>
                        <strong>
                            {this.state.help_usua_id === ""
                                ? `Accesos de Rol ${this.state.help_rol}`
                                : `Accesos de Usuario #ID ${this.state.help_usua_id}`}
                        </strong>
                    </Modal.Header>
                </Modal>
            </div>
        );
    }
}

export default Usuarios;
