import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import { Table, Row, Col, Container, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { FaTimes, FaCheck } from "react-icons/fa";
import { BsFillRecordFill } from "react-icons/bs";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
// import DetaCursoVistas from "../../../src/components/DetaCursoVistas";
import "../stylePages.css";

class AsignaMasivo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tper_id: 2,
            sect_id: 0,
            curs_id: 0,
            simo_id: 0,
            explica: "T",
            user_curso: 0,
            registros: [],
            usuarios: [],
            topicos: [],
            modulos: [],
            topicosCurso: [],
            sectores: [],
            personaTipo: [],
            cursosFiltro: [],
            cursosLista: [],
            cur_fila: 0,
            autosuggestValue: "",

            filterGrilla: "",
            login_usua_id: sessionStorage.getItem("USUARIO"),

            fetchRegistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
    }

    async getGrilla(a_tper_id, a_curs_id) {
        const sql = `${URL_DB}SEL_CURSOS_USUARIOS(1,${a_tper_id},${a_curs_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    usuarios:
                        Number(this.state.sect_id) === Number(-1)
                            ? response.data[0]
                            : response.data[0].filter(
                                  r =>
                                      r.usua_sect_id ===
                                      Number(this.state.sect_id)
                              )
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });
    }

    async getPersonaTipo() {
        const sql = `${URL_DB}SEL_PERSONAL_TIPO_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    personaTipo: response.data[0]
                    // tper_id: response.data[0][0].value
                });
            })
            .catch(error => console.log(error));
    }

    async getSectores() {
        const sql = `${URL_DB}SEL_USUA_SEDE_SECTOR_DD()`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    sectores: response.data[0],
                    sect_id: response.data[0][0].sect_id
                });
            })
            .catch(error => console.log(error));
    }

    async getModulos() {
        const sql = `${URL_DB}SEL_MODULO_DD(1)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    modulos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getCursosPersonaTipo(a_tper_id) {
        const sql = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${a_tper_id},0,'C')`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    cursosLista: response.data[0]
                });
            })
            .catch(error => console.log(error))
            .finally(() => {
                this.setState({ fetchRegistros: false });
            });
        const sql2 = `${URL_DB}SEL_CURSOS_TIPOPERS_USUAVISTA(1,${a_tper_id},0,'D')`;
        axios
            .get(sql2)
            .then(response => {
                this.setState({
                    topicos: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([
                this.getPersonaTipo(),
                this.getModulos(),
                this.getSectores(),
                this.getCursosPersonaTipo(2)
            ]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/

    filtrarDatos = () => {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.uso_deno) || regex.test(filtro.tema_deno)
        );
    };

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChangeAsigna = a_usua_id => {
        let usuarios = this.state.usuarios;

        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].usua_id === a_usua_id) {
                usuarios[i].si_no = usuarios[i].si_no === "S" ? "N" : "S";
                usuarios[i].aux = "S";
            }
        }

        this.setState({
            usuarios
        });
    };

    handleChangeOpc = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "simo_id") {
            this.setState({
                [name]: value,
                cursosFiltro: this.state.cursosLista.filter(f => {
                    return Number(f.simo_id) === Number(value);
                }),
                topicosCurso: [],
                curs_id: 0
            });
            return;
        }
        if (name === "curs_id") {
            this.setState(
                {
                    [name]: value,
                    topicosCurso: this.state.topicos.filter(f => {
                        return Number(f.ctop_curs_id) === Number(value);
                    })
                },
                () => {
                    this.getGrilla(this.state.tper_id, this.state.curs_id);
                }
            );
            return;
        }
        this.setState(
            {
                [name]: value
            },
            () => {
                this.getGrilla(this.state.tper_id, this.state.curs_id);
            }
        );
    };
    /*==========================================================================
       Actualizacion : asigna un curso a varios usuarios cuando graba
    *==========================================================================*/
    handleGrabar = event => {
        event.preventDefault();

        let v_usua_json = this.state.usuarios
            .filter(f => f.aux === "S")
            .map(item => {
                var obj = {};
                obj.usua_id = item.usua_id;
                obj.si_no = item.si_no;
                return obj;
            });

        if (v_usua_json.length === 0) {
            swal({
                title: "Nada para grabar",
                text: "No se efectuó esa acción",
                icon: "error",
                button: "Aceptar",
                timer: "2000"
            });
        } else {
            swal({
                title: `Grabar cambios curso #${this.state.curs_id}`,
                text: `Registrar ${v_usua_json.length} cambios, Confirma?`,
                icon: "warning",
                buttons: ["No", "Si"]
            }).then(respuesta => {
                if (respuesta === true) {
                    const sql = `${URL_DB}AM_ASIGNA_CURSO_MASIVO(${
                        this.state.login_usua_id
                    },${this.state.curs_id},'${String(
                        JSON.stringify(v_usua_json)
                    )}')`;
                    axios
                        .get(sql)
                        .then(response => {
                            this.setState(
                                {
                                    respuestaSp: response.data[0][0].respuesta
                                },
                                () => {
                                    if (this.state.respuestaSp === "OK") {
                                        this.setState({
                                            mensajeAlerta:
                                                "Registrado correctamente",
                                            mensajeColor: "green",
                                            respError: ""
                                        });
                                    } else {
                                        this.setState({
                                            respError: this.state.respuestaSp
                                        });
                                    }
                                }
                            );
                            this.getGrilla(this.state.curs_id);
                        })
                        .catch(error => {
                            alert(
                                "ERROR interno API al actualizar BD:" + error
                            );
                        });
                }
            });
        }
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Asigna Cursos Masivo</b>
                            </h2>
                        </Col>
                        <Col xs={7} />
                        <Col xs={1}>
                            <Button
                                variant="success"
                                size="sm"
                                onClick={this.handleGrabar}
                            >
                                Grabar
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5}>
                            {/* panel de cursos */}
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <b>Módulo</b>
                                        <select
                                            className="form-control"
                                            name="simo_id"
                                            value={this.state.simo_id}
                                            style={{
                                                backgroundColor: "#e6fff2",
                                                fontWeight: "bold"
                                            }}
                                            onChange={this.handleChangeOpc}
                                        >
                                            <option
                                                key={"0"}
                                                value={0}
                                                disabled
                                            >
                                                Selecione...
                                            </option>

                                            {this.state.modulos.map((m, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={m.simo_id}
                                                    >
                                                        {" "}
                                                        {m.simo_deno}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col md={9}>
                                    <Form.Group>
                                        <b>Seleccione Curso</b>
                                        <select
                                            className="form-control"
                                            name="curs_id"
                                            value={this.state.curs_id}
                                            onChange={this.handleChangeOpc}
                                        >
                                            <option key={0} value={0} disabled>
                                                Seleccione curso ...
                                            </option>
                                            {this.state.cursosFiltro.map(
                                                cur => {
                                                    return (
                                                        <option
                                                            key={cur.curs_id}
                                                            value={cur.curs_id}
                                                        >
                                                            {`${cur.curs_deno} / ${cur.perf_deno}  (${cur.curs_id})`}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        bordered
                                        hover
                                        size="sm"
                                        id="data_table"
                                    >
                                        <thead className="Grilla-header">
                                            <tr>
                                                <th
                                                    colSpan={2}
                                                >{`Tópicos curso #${this.state.curs_id}`}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.topicosCurso.map(
                                                (top, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td
                                                                style={{
                                                                    width: "5%",
                                                                    textAlign:
                                                                        "center"
                                                                }}
                                                            >
                                                                {top.ctop_orden}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "95%"
                                                                }}
                                                            >
                                                                {top.ctop_deno}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                        {/* panel de usuarios */}
                        <Col md={7}>
                            <Row>
                                <Col md={4}>
                                    <Form.Group>
                                        <b>Seleccione personal</b>
                                        <select
                                            className="form-control"
                                            name="tper_id"
                                            value={this.state.tper_id}
                                            style={{
                                                backgroundColor: "#e6fff2",
                                                fontWeight: "bold"
                                            }}
                                            onChange={this.handleChangeOpc}
                                        >
                                            {this.state.personaTipo.map(mod => {
                                                return (
                                                    <option
                                                        key={mod.value}
                                                        value={mod.value}
                                                    >
                                                        {mod.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col md={8}>
                                    <Form.Group>
                                        <b>Seleccione sector</b>
                                        <select
                                            className="form-control"
                                            name="sect_id"
                                            value={this.state.sect_id}
                                            onChange={this.handleChangeOpc}
                                        >
                                            {this.state.sectores.map(sec => {
                                                return (
                                                    <option
                                                        key={sec.sect_id}
                                                        value={sec.sect_id}
                                                    >
                                                        {sec.deno}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        bordered
                                        hover
                                        size="sm"
                                        id="data_table"
                                    >
                                        <thead className="Grilla-header">
                                            <tr>
                                                <th>Apellido</th>
                                                <th>Nombre</th>
                                                <th>Usuario</th>
                                                <th>F.Asign</th>
                                                <th>Ult.Vist</th>
                                                <th>Tópicos</th>
                                                <th>Asignar</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.registrosFiltrados &&
                                                "Cargando..."}

                                            {this.state.usuarios.map(
                                                (regis, i) => {
                                                    return (
                                                        <tr
                                                            key={i}
                                                            style={{
                                                                backgroundColor:
                                                                    regis.color
                                                            }}
                                                        >
                                                            <td
                                                                style={{
                                                                    width: "18%"
                                                                }}
                                                            >
                                                                {
                                                                    regis.usua_apellido
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "12%"
                                                                }}
                                                            >
                                                                {
                                                                    regis.usua_nombre
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "20%"
                                                                }}
                                                            >
                                                                {
                                                                    regis.usua_usuario
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "10%"
                                                                }}
                                                            >
                                                                {
                                                                    regis.cuas_fecha
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "10%"
                                                                }}
                                                            >
                                                                {
                                                                    regis.ult_fecha_vista
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "20%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="progress-bar progress-bar-striped bg-success"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width:
                                                                            regis.porcen_vista +
                                                                            "%"
                                                                    }}
                                                                    aria-valuenow={
                                                                        regis.porcen_vista
                                                                    }
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                    {String(
                                                                        regis.porcen_vista
                                                                    ) + "%"}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "5%"
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() =>
                                                                        this.handleChangeAsigna(
                                                                            regis.usua_id
                                                                        )
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            regis.si_no ===
                                                                            "S"
                                                                                ? "#1A73E8"
                                                                                : "white",
                                                                        color:
                                                                            regis.si_no ===
                                                                            "S"
                                                                                ? "white"
                                                                                : "black",
                                                                        textAlign:
                                                                            "center",
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    {regis.si_no ===
                                                                        "S" && (
                                                                        <FaCheck />
                                                                    )}
                                                                    {regis.si_no ===
                                                                        "N" && (
                                                                        <FaTimes />
                                                                    )}
                                                                </div>
                                                            </td>

                                                            <td
                                                                style={{
                                                                    width: "5%"
                                                                }}
                                                            >
                                                                {regis.aux ===
                                                                    "S" && (
                                                                    <BsFillRecordFill
                                                                        color={
                                                                            "red"
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                        {registrosFiltrados.length > 0 && (
                                            <tfoot className="Grilla-header">
                                                <tr>
                                                    <td
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >{`${this.state.usuarios.length} Reg`}</td>
                                                    <td colSpan={7} />
                                                </tr>
                                            </tfoot>
                                        )}
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AsignaMasivo;
