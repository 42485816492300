import React from "react";
import { URL_DB } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Alert,
    Modal
} from "react-bootstrap";
// import exportToCSV from "../../utils/functions/export-excel";
import { FaRegEdit } from "react-icons/fa";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import Select from "react-select";
import "../../pages/stylePages.css";

class ModuloSector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lee_mod: props.lee_mod,
            registros: [],
            sedes: [],
            login_id: sessionStorage.getItem("USUARIO"),
            login_usua_nivel: sessionStorage.getItem("USUARIO_NIVEL"),
            sect_id: 0,
            sect_deno: "",
            sede_deno: "",
            filterGrilla: "",
            modulos: [],
            selectedOption: [],
            showModalEdit: false,
            fetchRegistros: false,
            expSql: "",
            expTitulo: "Reporte de Modulos",
            expSubtit: "",
            respuestaSp: [],
            respError: "",
            mensajeAlerta: ""
        };
    }

    async getModulosSectores() {
        const sql = `${URL_DB}SEL_MODULOS_USUA_SECTORES(null)`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                });
            })
            .catch(error => console.log(error));
    }

    async getModulos(a_sect_id) {
        const sql = `${URL_DB}SEL_SECTOR_MODULOS_DDM(${a_sect_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    modulos: response.data[0],
                    selectedOption: response.data[0].filter(
                        elem => elem.si_no === "S"
                    )
                });
                console.log(response.data[0]);
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getModulosSectores(), this.getModulos(0)]);
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInicio();
    }

    /*==========================================================================
        Utilidades de filtros y sort para la grilla ppal 
    *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.sect_deno) ||
                regex.test(filtro.sede_deno) ||
                regex.test(filtro.modulos)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleChangeModulo = selectedOption => {
        this.setState({ selectedOption });
    };

    handleChange = event => {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleModalEdit = regis => {
        this.setState({
            showModalEdit: true,
            sect_id: regis.sect_id,
            sect_deno: regis.sect_deno,
            sede_deno: regis.sede_deno,
            respError: "",
            mensajeAlerta: "",
            mensajeColor: "N"
        });
    };

    handleModalExit = () => {
        this.setState({ showModalEdit: false });
        this.setState({
            mensajeAlerta: "No se aplicaron cambios",
            mensajeColor: "red"
        });
    };

    /*==========================================================================
        Actualizacion : Modificacion a base de datos
    *==========================================================================*/
    // u}','${encodeURIComponent(this.state.soci_nota)}','${String(JSON.stringify(this.state.selectedOption))}')`;
    handleGrabar = e => {
        let modulos = this.state.selectedOption.map(e => {
            let obj = {};
            obj.simo_id = e.value;
            return obj;
        });

        const sql = `${URL_DB}AM_SIST_MODULO_USUA_SECTOR(${
            this.state.login_id
        },${this.state.sect_id},'${String(JSON.stringify(modulos))}')`;
        axios
            .get(sql)
            .then(response => {
                this.setState({
                    respuestaSp: response.data[0]
                });
                var obj = this.state.respuestaSp[0];
                this.setState({
                    respError: obj.respuesta
                });

                if (this.state.respError === "OK") {
                    this.setState({
                        mensajeAlerta: "Registrado correctamente",
                        mensajeColor: "green",
                        showModalEdit: false
                    });
                    this.getModulosSectores();
                }
            })
            .catch(error => {
                alert("ERROR interno API al actualizar BD:" + error + sql);
            });
    };

    /*==========================================================================
        RENDER
    *==========================================================================*/

    render() {
        const registrosFiltrados = this.filtrarDatos();
        const respError = this.state.respError;
        //const mensajeAlerta = this.state.mensajeAlerta;
        //const mensajeColor = this.state.mensajeColor;

        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Col xs={4} style={{ fontSize: "22px" }}>
                            <b>Relación Módulos-Sectores</b>
                        </Col>
                        <Col xs={3}>
                            <Form.Group>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}></Col>
                        <Col xs={1}></Col>
                        <Col xs={1}></Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sect_id"
                                                    )
                                                }
                                            >
                                                #ID
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sect_deno"
                                                    )
                                                }
                                            >
                                                Denominación
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "sede_deno"
                                                    )
                                                }
                                            >
                                                sedes
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "modulos"
                                                    )
                                                }
                                            >
                                                Módulos relacionados
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("canti")
                                                }
                                            >
                                                #Usuarios
                                            </Button>
                                        </th>

                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{regis.sect_id}</td>
                                                <td>{regis.sect_deno}</td>
                                                <td>{regis.sede_deno}</td>
                                                <td>{regis.modulos}</td>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {regis.canti}
                                                </td>

                                                <td>
                                                    {this.state
                                                        .login_usua_nivel ===
                                                        "A" && (
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={() =>
                                                                    this.handleModalEdit(
                                                                        regis
                                                                    )
                                                                }
                                                            >
                                                                <FaRegEdit />
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModalEdit}
                    onHide={this.handleModalExit}
                    dialogClassName="modal-40"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`${this.state.sect_deno} - ${this.state.sede_deno}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Select
                                        name="form-field-name"
                                        closeMenuOnSelect={false}
                                        isClearable={false}
                                        value={this.state.selectedOption}
                                        onChange={this.handleChangeModulo}
                                        isMulti
                                        options={this.state.modulos}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs={8} style={{ textAlign: "right" }}>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={this.handleGrabar}
                                    >
                                        Grabar
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={this.handleModalExit}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Alert
                                        key="1"
                                        variant="danger"
                                        show={
                                            respError.length > 3 ? true : false
                                        }
                                    >
                                        {respError}
                                    </Alert>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModuloSector;
