import React from "react";
import "./detalleChild.css";
import { BsChatText } from "react-icons/bs";

class DetaCursoVistas extends React.Component {
    constructor(props) {
        super();

        this.state = {
            filas: props.regDetalle
        };
    }

    render() {
        console.log(this.state.filas);
        return (
            <>
                <div>
                    <table>
                        <thead className="cabeChild">
                            <tr>
                                {/* 370 */}
                                <th style={{ width: "30px" }}>#Ord</th>
                                <th style={{ width: "180px" }}>Tópico</th>
                                <th style={{ width: "30px" }}>%Visto</th>
                                <th
                                    style={{
                                        width: "70px",
                                        textAlign: "center"
                                    }}
                                >
                                    Ult.vista
                                </th>
                                <th style={{ width: "30px" }}>#Vistas</th>
                            </tr>
                        </thead>
                        <tbody className="detaChild">
                            {this.state.filas.map((elem, i) => {
                                return (
                                    <>
                                        <tr key={i * 2}>
                                            <td style={{ textAlign: "center" }}>
                                                {elem.ctop_orden}
                                            </td>
                                            <td>{elem.ctop_deno}</td>
                                            <td style={{ textAlign: "right" }}>
                                                {elem.ctuv_vista_porcen}%
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {elem.ctuv_fecha}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {elem.ctuv_canti}
                                            </td>
                                        </tr>
                                        {String(elem.ctuv_comentario).length >
                                            1 && (
                                            <tr key={i + 1}>
                                                <td
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <BsChatText />
                                                </td>
                                                <td
                                                    colSpan={4}
                                                    style={{
                                                        backgroundColor:
                                                            "#ffcccc"
                                                    }}
                                                >
                                                    {elem.ctuv_comentario}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default DetaCursoVistas;
