/*const baseUrl = process.env.NODE_ENV === 'production' 
? "api-ergoapp.marianodrets.a2hosted.com" 
: "http://localhost:3001" */
//const baseUrl = "https://api-net2panel.marianodrets.a2hosted.com";
const baseUrl = "https://api-gurudoc.marianodrets.a2hosted.com";
//const baseUrl = "http://localhost:3001";

export const URL_DBQUERY = `${baseUrl}/`;
export const URL_DB = `${baseUrl}/spget/`;
export const URL_TOKEN = `${baseUrl}/spgettokken`;
export const USR_LOG = "2";
export const NOMBRE_SIST = "OmintTasy";

export const DIASEM = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado"
];
